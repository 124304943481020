import { Table } from 'antd';
import styled from 'styled-components';

const StyledTableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
`;

const TransformedTable = ({ data }) => {
  if (!data || !data.Labels || !data.Values) return null;

  const columns = [
    {
      title: data.Labels.Legend || 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text) => <span style={{ fontWeight: 500, color: '#111827' }}>{text}</span>,
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#f9fafb',
          borderRight: '1px solid #e5e7eb',
        },
      }),
    },
    ...data.Values.map((value, index) => ({
      title: <span style={{ fontWeight: 500 }}>{value.Legend}</span>,
      dataIndex: `value${index}`,
      key: `value${index}`,
      render: (text) => {
        let displayText = text;
        if (value.Prefix) displayText = value.Prefix + displayText;
        if (value.Postfix) displayText = displayText + value.Postfix;
        
        // Highlight numeric values with color
        const isNumeric = !isNaN(parseFloat(text)) && isFinite(text);
        return (
          <span style={{ 
            color: isNumeric ? '#1d4ed8' : '#374151',
            fontWeight: isNumeric ? 500 : 400 
          }}>
            {displayText}
          </span>
        );
      },
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#f9fafb',
          borderRight: index === data.Values.length - 1 ? 'none' : '1px solid #e5e7eb',
        },
      }),
    })),
  ];

  // Prepare data for the table
  const tableData = data.Labels.Data.map((label, index) => {
    const rowData = {
      key: index,
      category: label,
    };
    
    data.Values.forEach((value, valueIndex) => {
      rowData[`value${valueIndex}`] = value.Data[index];
    });
    
    return rowData;
  });

  return (
    <StyledTableContainer>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered={false}
        size="middle"
        style={{
          width: '100%',
        }}
        onRow={(record) => ({
          style: {
            cursor: 'pointer',
          },
        })}
        components={{
          header: {
            cell: (props) => (
              <th {...props} style={{ 
                ...props.style,
                padding: '12px 16px',
                borderBottom: '1px solid #e5e7eb',
              }} />
            ),
          },
          body: {
            cell: (props) => (
              <td {...props} style={{ 
                ...props.style,
                padding: '12px 16px',
                borderBottom: '1px solid #e5e7eb',
                transition: 'background-color 0.2s',
              }} />
            ),
            row: (props) => (
              <tr {...props} style={{ 
                ...props.style,
                ':hover': {
                  backgroundColor: '#f8fafc',
                },
              }} />
            ),
          },
        }}
      />
    </StyledTableContainer>
  );
};

export default TransformedTable