import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { LuFileSpreadsheet } from "react-icons/lu";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MasterTable from "../../components/MasterTable";
import { media } from "../../utils/media";
import { TailSpin } from "react-loader-spinner";
import { onDownloadExcelClickCT } from "../../utils/clevertap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNewExcelsData,
  fetchNewFilesData,
  selectnewexcelData,
  selectnewFileData,
} from "../../utils/redux/slices";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import toast from "react-hot-toast";
const { RangePicker } = DatePicker;
const getNameHeader = (count) => {
  return (
    <CountTextWrap>
      <HeaderNameDiv>Report Name</HeaderNameDiv>
      <CountText>
        ({count} {count <= 1 ? "Result" : "Results"})
      </CountText>
    </CountTextWrap>
  );
};

const DownloadPage = () => {
  const [columnsData, setColumnsData] = useState([
    {
      key: "name",
      value: getNameHeader(0),
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "250px",
      fontSize: "16px",
    },
    {
      key: "startDate",
      value: <ColumnText>First Month Data</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "80px",
    },
    {
      key: "endDate",
      value: <ColumnText>Last Month Data</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "150px",
      style: { fontSize: window.innerWidth <= 768 ? "12px" : "16px" },
    },
    {
      key: "updated",
      value: <ColumnText>Last Updated</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "250px",
    },
    {
      key: "download",
      value: <ColumnText>File Link</ColumnText>,
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "180px",
      align: "center",
      style: { fontSize: window.innerWidth <= 768 ? "12px" : "16px" },
    },
  ]);
  const [weeklyrowsData, setweeklyRowsData] = useState([]);
  const [weeklystyledRowsData, setweeklyStyledRowsData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [styledRowsData, setStyledRowsData] = useState([]);
  const [excelLoaderState, setExcelLoaderState] = useState({});
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const is_athena = process.env.REACT_APP_API_ENDPOINT.includes("athena");
  const dispatch = useDispatch();
  const newexcelaccessData = useSelector(selectnewexcelData);
  const newfileaccessData = useSelector(selectnewFileData);
  const [activeTab, setActiveTab] = useState("monthly");
  const [excelLoaderStateweekly, setExcelLoaderStateweekly] = useState({});
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
  });

  const typeMapping = {
    weekly: "Weekly Report",
    monthly: "Monthly Report",
    quarterly: "Quarterly Report",
    one_off: "One off Report",
    workshop: "Workshop Deck",
    // others: "Others" (if needed in future)
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const showEndPicker = windowWidth <= 480;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);
  const filterRows = () => {
    let filteredData = [...weeklyrowsData]; 
    if (formData?.startDate && formData?.endDate) {
      const startDate = moment(formData.startDate).startOf("day");
      const endDate = moment(formData.endDate).endOf("day");
      filteredData = filteredData.filter((row) =>
          moment(row.player.updated).isBetween(startDate, endDate, "day", "[]")
      );
   }
    return filteredData;
  };

  const filteredWeeklyRows = useMemo(() => {
    return filterRows();
  }, [weeklyrowsData, formData]);

  const [weeklycolumnsData, setweeklyColumnsData] = useState([
    {
      key: "name",
      value: getNameHeader(0),
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "80px",
    },
    {
      key: "file_name",
      value: <ColumnText>File Name</ColumnText>,
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "80px",
    },
    {
      key: "type",
      value: <ColumnText>Output Type</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "80px",
    },
    {
      key: "startDate",
      value: <ColumnText>First Month Data</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "80px",
    },
    {
      key: "endDate",
      value: <ColumnText>Last Month Data</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "150px",
    },
    {
      key: "updated",
      value: <ColumnText>Last Updated</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "250px",
    },
    {
      key: "download",
      value: <ColumnText>File Link</ColumnText>,
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "180px",
      align: "center",
    },
  ]);

  const downloadExcel = async (value, index) => {
    setExcelLoaderState((prevState) => ({ ...prevState, [index]: true }));

    let client_id = window.localStorage.getItem("clientID");
    let report_name = value.name;
    let report_id = value.id;
    const industry_id = IndustryDict[report_id]?.industry_id;
    let url = `${
      process.env.REACT_APP_BM_ENDPOINT
    }/newexcel/?client_id=${client_id}&report_id=${report_id}&is_athena=false&industry_id=${industry_id}&email_id=${window.localStorage.getItem(
      "email"
    )}`;

    const MAX_ATTEMPTS =
      Number(window.localStorage.getItem("clientID")) === 53 &&
      is_athena === true
        ? 0
        : 3;
    const RETRY_DELAY = 20000;

    for (let attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        console.log(`Attempt ${attempt} to fetch the file...`);
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const res = await response.json();
        if (res["excel_link"]) {
          const link = document.createElement("a");
          link.href = res["excel_link"];
          link.download = report_name;
          link.click();

          setExcelLoaderState((prevState) => ({
            ...prevState,
            [index]: false,
          }));
          onDownloadExcelClickCT(report_name, report_id, client_id.key, true);
          return;
        }
      } catch (error) {
        console.error(`Error on attempt ${attempt}:`, error);
        if (attempt === MAX_ATTEMPTS) {
          console.log(
            "Max attempts reached. Falling back to the existing flow..."
          );
        } else {
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }
      }
    }

    fetch(url, {
      method: "GET",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const link = document.createElement("a");
        link.href = res["excel_link"];
        link.download = report_name;
        link.click();

        setExcelLoaderState((prevState) => ({ ...prevState, [index]: false }));
        onDownloadExcelClickCT(report_name, report_id, client_id.key, true);
      })
      .catch((error) => {
        setExcelLoaderState((prevState) => ({ ...prevState, [index]: false }));
        onDownloadExcelClickCT(report_name, report_id, client_id.key, false);
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch(fetchNewExcelsData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNewFilesData());
  }, [dispatch]);

  useEffect(() => {
    if (newexcelaccessData.length > 0) {
      const formattedData = newexcelaccessData.map((item, index) => ({
        player: {
          name: item.name,
          isSubscribed: index % 2 === 0,
          bookMarkValue: 0,
          sortingValue: index * 100,
          id: item.id,
          start_date: item.start_date,
          end_date: item.end_date,
          updated:item.last_updated
        },
      }));
      setRowsData(formattedData);
    }
  }, [newexcelaccessData]);

  useEffect(() => {
    if (newfileaccessData.length > 0) {
      const formattedData = newfileaccessData.map((item, index) => ({
        player: {
          name: IndustryDict[item.report]?.report_name,
          isSubscribed: index % 2 === 0,
          bookMarkValue: 0,
          sortingValue: index * 100,
          id: item.report,
          start_date: item.start_date,
          end_date: item.end_date,
          updated: item.created,
          file: item.file,
          type: item.type,
          file_name: item.file,
        },
      }));

      setweeklyRowsData(formattedData);
    }
  }, [newfileaccessData]);

  const sortRows = useCallback(() => {
    const sortedData = rowsData.map((row, index) => ({
      name: {
        value: (
          <NameCell
            onClick={() => {
              const url = `/Report3/?val=${row.player.name}&key=${row.player.id}&filter=${row.player.filter}&filter_value=${row.player.filter_value}`;
              window.open(url, "_blank");
            }}
          >
            {row.player.name}
          </NameCell>
        ),
        sortingValue: row.player.sortingValue,
      },
      startDate: {
        value: (
          <TextCell>
            {moment(row.player.start_date).format("Do MMM YYYY")}
          </TextCell>
        ),
      },
      endDate: {
        value: (
          <TextCell>
            {moment(row.player.end_date).format("Do MMM YYYY")}
          </TextCell>
        ),
      },
      updated: {
        value: (
          <TextCell>
            {row.player.updated === null ? "Up to Date":row.player.updated}
          </TextCell>
        ),
      },
      download: {
        value: (
          <ExcelButtonWrapper>
            <ExcelButton onClick={() => downloadExcel(row.player, index)}>
              {excelLoaderState[index] ? (
                <LoaderWrapper>
                  <TailSpin
                    height="20"
                    width="20"
                    color="#0066CC"
                    ariaLabel="loading"
                    radius="1"
                    visible={true}
                  />
                  <LoadingText>Preparing...</LoadingText>
                </LoaderWrapper>
              ) : (
                <>
                  <LuFileSpreadsheet size={18} />
                  <span>Download Excel</span>
                </>
              )}
            </ExcelButton>
          </ExcelButtonWrapper>
        ),
      },
    }));

    setStyledRowsData(sortedData);
  }, [excelLoaderState, rowsData]);

  const sortWeeklyRows = useCallback(() => {
    const sortedData = filteredWeeklyRows.map((row, index) => ({
      name: {
        value:
          row.player.type === "others" ? (
            <OthersNameCell>Others</OthersNameCell>
          ) : (
            <NameCell
              onClick={() => {
                const url = `/Report3/?val=${row.player.name}&key=${row.player.id}&filter=${row.player.filter}&filter_value=${row.player.filter_value}`;
                window.open(url, "_blank");
              }}
            >
              {row.player.name}
            </NameCell>
          ),
        sortingValue: row.player.sortingValue,
      },
      file_name: {
        value: (
          <TextCell>
            {row.player?.file_name?.replace("/media/clientWeeklyFiles/", "")}
          </TextCell>
        ),
      },
      type: {
        value: <TextCell>{typeMapping[row.player.type]}</TextCell>,
      },
      startDate: {
        value: (
          <TextCell>
            {moment(row.player.start_date).format("Do MMM YYYY")}
          </TextCell>
        ),
      },
      endDate: {
        value: (
          <TextCell>
            {moment(row.player.end_date).format("Do MMM YYYY")}
          </TextCell>
        ),
      },
      updated: {
        value: (
          <TextCell>
            {moment(row.player.updated).format("Do MMM YYYY")}
          </TextCell>
        ),
      },
      download: {
        value: (
          <ExcelButtonWrapper>
            <ExcelButton onClick={() => downloadFile(row.player.file, index)}>
              {excelLoaderStateweekly[index] ? (
                <LoaderWrapper>
                  <TailSpin
                    height="20"
                    width="20"
                    color="#0066CC"
                    ariaLabel="loading"
                    radius="1"
                    visible={true}
                  />
                  <LoadingText>Preparing...</LoadingText>
                </LoaderWrapper>
              ) : (
                <>
                  <LuFileSpreadsheet size={18} />
                  <span>Download File</span>
                </>
              )}
            </ExcelButton>
          </ExcelButtonWrapper>
        ),
      },
    }));
    setweeklyStyledRowsData(sortedData);
  }, [filteredWeeklyRows, excelLoaderStateweekly]);

  const downloadFile = async (fileUrl, index) => {
    try {
      setExcelLoaderStateweekly((prevState) => ({
        ...prevState,
        [index]: true,
      }));
      await new Promise((resolve) => setTimeout(resolve, 5000));
      const fileUrl1 = `${process.env.REACT_APP_API_ENDPOINT}${fileUrl}`;
      const response = await fetch(fileUrl1, { method: "GET" });

      if (!response.ok) throw new Error("File download failed");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.download = fileUrl.split("/").pop() || "downloaded_file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    } finally {
      setExcelLoaderStateweekly((prevState) => ({
        ...prevState,
        [index]: false,
      }));
    }
  };

  useEffect(() => {
    if (rowsData.length > 0) {
      setColumnsData((prevColumnsData) => [
        {
          ...prevColumnsData[0],
          value: getNameHeader(rowsData.length),
        },
        ...prevColumnsData.slice(1),
      ]);
      sortRows();
    }
  }, [rowsData, sortRows]);

  useEffect(() => {
    if (weeklyrowsData.length > 0) {
      setweeklyColumnsData((prevColumnsData) => [
        {
          ...prevColumnsData[0],
          value: getNameHeader(weeklyrowsData.length),
        },
        ...prevColumnsData.slice(1),
      ]);
      sortWeeklyRows();
    }
  }, [weeklyrowsData, sortWeeklyRows]);

  return (
    <PageWrapper>
      <Header />
      <TabContainer>
      <TabButtonsWrapper>
        <TabButton
          active={activeTab === "monthly"}
          onClick={() => setActiveTab("monthly")}
        >
          Monthly Sector Reports
        </TabButton>
        <TabButton
          active={activeTab === "weekly"}
          onClick={() => setActiveTab("weekly")}
        >
          Others Report
        </TabButton>
      </TabButtonsWrapper>
      {activeTab === "weekly" && <DropdownWrapper>
        <Label>Filter By Last Updated Date</Label>
        <RangePicker
            format="MM/DD/YYYY"
            value={
              formData.startDate && formData.endDate
                ? [dayjs(formData.startDate), dayjs(formData.endDate)]
                : [null, null]
            }
            onChange={(dates, dateStrings) => {
              const startDate = dateStrings[0] ? dayjs(dateStrings[0]).format("YYYY-MM-DD") : null;
              const endDate = dateStrings[1] ? dayjs(dateStrings[1]).format("YYYY-MM-DD") : null;
          
              if (startDate && endDate && dayjs(endDate).isBefore(dayjs(startDate))) {
                toast.error("End date cannot be earlier than the start date");
                return;
              }
          
              setFormData({
                startDate,
                endDate,
              });
            }}
            className={
              showEndPicker
                ? "custom-range-picker-dropdown show-end"
                : "custom-range-picker-dropdown"
            }
            style={{ width:"80%", height: windowWidth <= "768" ? "": "40px", borderRadius: "8px" }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            key={windowWidth}
          />
        </DropdownWrapper>}
      </TabContainer>
      <GridWraper>
        {activeTab === "monthly" ? (
          <MasterTable
            columnsData={columnsData}
            rowsData={styledRowsData}
            headerBackground="#FFFFFF"
            autoCellHeight={true}
            toolGap={-4}
          />
        ) : (
          <MasterTable
            columnsData={weeklycolumnsData}
            rowsData={weeklystyledRowsData}
            headerBackground="#FFFFFF"
            autoCellHeight={true}
            toolGap={-4}
          />
        )}
      </GridWraper>
      <Footer />
    </PageWrapper>
  );
};

export default DownloadPage;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eef9ff;
  min-height: 100vh;
`;

const CountTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderNameDiv = styled.div`
  font-size: 16px;
  ${media.small`
  font-size: 12px;
  `}
`;

const CountText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
  ${media.small`
  font-size: 12px;
  padding-left: 5px;
  line-height: 12px;
  `}
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
  margin-bottom: 10px;
  min-height: 85vh;
  gap: 20px;
  ${media.small`
     margin-bottom: 10px;
    `}
`;
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LoadingText = styled.span`
  font-size: 14px;
  color: #64748b;
`;

const ExcelButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  ${media.small`
    font-size: 12px;
    `}
  &:hover {
    background-color: #e2e8f0;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const ExcelButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const NameCell = styled.div`
  font-weight: 500;
  color: #0099ff;
  font-size: 14px;
  padding: 4px 0;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
  ${media.small`
    font-size: 12px;
    `}
`;
const OthersNameCell = styled.div`
  font-weight: 500;
  color: black;
  font-size: 14px;
  padding: 4px 0;
  text-decoration: none;
  ${media.small`
    font-size: 12px;
    `}
`;

const TextCell = styled.div`
  font-weight: 500;
  color: #00000;
  font-size: 14px;
  padding: 4px 0;
  text-decoration: none;
  cursor: pointer;
  ${media.small`
    font-size: 12px;
    `}
`;

const TabButtonsWrapper = styled.div`
  display: flex;
  gap: 0px;
  flex: 1;
  overflow-x: auto;
  padding: 10px 3.5vw 0px;
  ${media.small`
    flex-wrap: wrap;
  `}
  &:focus {
    outline: none;
  }
`;

const TabButton = styled.button`
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-bottom: ${({ active }) =>
    active ? "2px solid rgb(0, 153, 255)" : "none"};
  font-size: 14px;
  transition: background-color 0.3s;
  ${media.small`
      font-size: 10px;
        padding: 4.5px;
    `}
  &:hover {
    background-color: #f0f0f0;
  }
`;
const ColumnText = styled.div`
  font-size: 16px;

  ${media.small`
    font-size: 12px;
  `}
`;
const DropdownWrapper = styled.div`
  align-items: center;
  justify-content: flex-end;
  flex-direction:column;
  width: auto;
  position: relative;
  margin-right: -8px;
  ${media.small`
    width: 76%;
    height: 34px;
    font-size: 12px;
    margin-top:-34px;
    margin-right: -55px;
    padding: 10px 3.5vw 0px;
  `}
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const Label = styled.span`
  color: black;
  font-size: 14px;
  ${media.small`
      font-size: 12px;

    `}
`;
