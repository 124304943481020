import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
const myColors = [
  "#0099FF",
  "#A962FF",
  "#26CDCD",
  "#9FC51E",
  "#FF832B",
  "#FCBB53",
  "#21AF4A",
  "#66C2FF",
  "#4673FF",
  "#F28D8C",
  "#7D7DE8",
  "#AFEEEE",
  "#DA70D6",
  "#98FB98",
  "#F58CBD",
  "#FFDD44",
  "#89CFF0",
  "#FF6F61",
  "#B1EDE8",
  "#C9A0DC",
  "#93DFB8",
  "#FFD700",
  "#F4A460",
  "#ADD8E6",
  "#FFB347",
  "#77DD77",
  "#DDA0DD",
  "#FFC0CB",
  "#FF6961",
  "#B39EB5",
  "#FFB6C1",
];

const blurNumber = (value, blurChar = "█") => {
  if (value === null || value === undefined) return value;
  const valueStr = String(value);
  const blurredValue = blurChar.repeat(valueStr.length);
  return blurredValue;
};
const addOpacity = (hexColor, opacity) => {
  const hex = hexColor.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const myColorsWithOpacity = myColors.map((color) => addOpacity(color, 0.8));

const calculateBarWidth = (wrapperWidth, numberOfBars, paddingRatio) => {
  let barWidth =
    (wrapperWidth * (1 - paddingRatio)) /
    ((1 - paddingRatio) * numberOfBars + paddingRatio * (numberOfBars + 1));
  let barPadding =
    (wrapperWidth * paddingRatio) /
    ((1 - paddingRatio) * numberOfBars + paddingRatio * (numberOfBars + 1));
  barPadding = Math.floor(barPadding);
  barWidth = barWidth.toFixed(0);
  return { barWidth, barPadding };
};
const TransformedNivoBarGraph = ({ data }) => {
  const [transformedData, setTransformedData] = useState([]);
  const [transformedData2, setTransformedData2] = useState([]);
  const [isScale, setScale] = useState(false);
  const [isCurrency, setCurrency] = useState(false);
  const [wrapperWidth, setWrapperWidth] = useState(null);
  const [title, setTitle] = useState(data?.Title || "");
  const [isUSDPresent, setIsUSDPresent] = useState(false);
  const [usdToINRRate, setUsdToINRRate] = useState(0);
  const wrapperRef = useRef(null);
  const legendPadding = 50;
  const fontSize = 9;
  const tickRotation = 0;
  const marginTop = 50;
  const marginRight = 50;
  const marginBottom = 70;
  const marginLeft = 60;
  const is_platform = false;
  const bigSize = 15;
  const smallSize = 5;
  const formatCode = "hi";
  const frequency = "M";
  const is_percent = false;
  const thousandScaling = false;
  const isBlurred = false;
  const legendDirection = "row";
  const topOption = [];
  const paddingRatio = 0.5;
  const showTotal = false;
  const is_usd = false;
  const isINR = data?.Title?.includes("INR");
  const isHashTitle = data?.Title?.includes("#");
  const maxValue = Math.max(...data.Values.flatMap((v) => v.Data));
  const isMnScale = isHashTitle && maxValue >= 1_000_000;
  const isCrScale = isINR && maxValue >= 10_000_000;
  const yAxisUnit = isMnScale ? "" : 
                 isCrScale ? "" : 
                 data?.Values?.[0]?.Unit || "";
  const currency_unit = isCurrency
    ? isScale
      ? ` (${is_usd ? "USD" : "INR"} ${is_usd ? "Bn" : "Mn"})`
      : ` (${is_usd ? "USD" : "INR"})`
    : isScale
      ? ` (${is_usd ? "Mn" : "Cr"})`
      : ``;
  useEffect(() => {
    if (!data || !data.Values || !data.Labels || !data.Labels.Data) {
      return;
    }
    try {
      let newTransformed = [];
      let dataList = [];
      
      data.Labels.Data.forEach((label, index) => {
        const color = myColorsWithOpacity[index % myColorsWithOpacity.length];
        const value = data.Values[0].Data[index] || 0;
        
        if (value > 0) {
          newTransformed.push({
            year: label,
            value: value,
            color: color
          });
        }
  
        dataList.push({
          id: label,
          color: color,
          data: [{
            x: label,
            y: value
          }]
        });
      });
  
      setTransformedData(newTransformed);
      setTransformedData2(dataList);
    } catch (error) {
      console.error("Error transforming data:", error);
    }
  }, [data]);
  useEffect(() => {
    if (!data?.Title) return;

    const usdMatch = data.Title.match(/USD=([\d.]+)\s*INR/);
    if (usdMatch) {
      setIsUSDPresent(true);
      setUsdToINRRate(parseFloat(usdMatch[1]));     
      const maxValue = Math.max(...data.Values.flatMap((v) => v.Data));
      if (maxValue >= 1_000_000) {
        setTitle(data.Title.replace(/USD=[\d.]+ INR/, `USD=${usdMatch[1]} INR (USD Mn)`));
        setScale(true);
      } else {
        setTitle(data.Title);
      }
    } else {
      setTitle(data.Title);
    }
  }, [data]);

  const CustomLegendLayer = ({ series }) => {
    const isRow = legendDirection === "row";
    const effectiveMarginLeft =
      marginLeft -
      barWidth / 2 +
      (wrapperWidth -
        transformedData.length * barWidth -
        (transformedData.length + 1) * barPadding) /
      2;

    let xPosition = legendPadding - effectiveMarginLeft;
    let yPosition = -marginTop + bigSize / 2;

    return (
      <g>
        {series?.map((item, index) => {
          const matchingItem = transformedData.find(d => d.year === item.id);
          const color = matchingItem?.color || myColorsWithOpacity[index % myColorsWithOpacity.length];
          const xCurrentPosition = xPosition;
          xPosition = (item.id.length * fontSize) / 2 + xPosition + 30;
          const yCurrentPosition = yPosition;
          yPosition = yPosition + 12;
          return (
            <g
              key={item.id}
              transform={
                isRow
                  ? `translate(${xCurrentPosition},${-marginTop + bigSize / 2})`
                  : `translate(${legendPadding - effectiveMarginLeft},${yCurrentPosition})`
              }
            >
              <circle cx={10} cy={0} r={bigSize / 2} fill={color} />
              <text
                x={20}
                y={0}
                textAnchor="start"
                alignmentBaseline="middle"
                fill="#000000"
                fontSize={fontSize}
              >
                {item.id}
              </text>
            </g>
          );
        })}
      </g>
    );
  };

  if (!transformedData || transformedData.length === 0) {
    return <div>No Data Found.</div>;
  }

  const CustomTooltip = ({ slice }) => {
    if (!slice || !slice.points) return null;
    const nonZeroPoints = slice.points.filter(point => {
      const value = point.data.y;
      return value !== null && value !== undefined && value !== 0;
    });  
    if (nonZeroPoints.length === 0) return null;
  
    const reversedData = [...nonZeroPoints].reverse();
    const topData = reversedData.filter(
      (obj) => (topOption.length === 0 || topOption.includes(obj.serieId)) &&
               obj.serieId !== "Overall"
    );
    
    const totalValue = topData.reduce((sum, dataPoint) => sum + dataPoint.data.y, 0);
    const totalValueList = reversedData.filter(obj => obj.serieId === "Overall");
  
    const otherData = reversedData.filter(
      (obj) => !topData.includes(obj) && obj.serieId !== "Overall"
    );
  
    const getSeriesColor = (serieId) => {
      const matchingItem = transformedData.find(d => d.year === serieId);
      return matchingItem?.color || 
             myColorsWithOpacity[transformedData.findIndex(d => d.year === serieId) % myColorsWithOpacity.length];
    };
  
    const formatTooltipValue = (value) => {
      let scaledValue, unit;
      if (isMnScale) {
        scaledValue = value / 1_000_000;
        unit = isUSDPresent ? "(Mn)" : "(Mn)";
      } else if (isCrScale) {
        scaledValue = value / 10_000_000;
        unit = "(INR Cr)";
      } else {
        scaledValue = value;
        unit = is_percent ? "%" : currency_unit;
      }

      const formattedValue = scaledValue?.toLocaleString(formatCode, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      
      return { formattedValue, unit };
    };
  
    return (
      <TootTipWrapper fontSize={fontSize}>
        {showTotal && totalValueList.length > 0 && (
          <TootTipRow>
            <TootTipLabel bold={true}>
              <SquareBox size={bigSize} color={"#cccccc"} bold={true} />
              Overall
            </TootTipLabel>
            <TootTipValue bold={true}>
              {isBlurred
                ? blurNumber(formatTooltipValue(totalValueList[0].data.y).formattedValue)
                : formatTooltipValue(totalValueList[0].data.y).formattedValue}
              <TootTipValueUnit fontSize={Number(fontSize * 0.8)} bold={true}>
                {formatTooltipValue(totalValueList[0].data.y).unit}
              </TootTipValueUnit>
            </TootTipValue>
          </TootTipRow>
        )}
        {topData.map((point, idx) => {
          const formatted = formatTooltipValue(point.data.y);
          const seriesColor = getSeriesColor(point.serieId);
          
          return (
            <TootTipRow key={idx}>
              <TootTipLabel>
                <SquareBox size={bigSize} color={seriesColor} />
                {point.serieId}
              </TootTipLabel>
              <TootTipValue>
                {isBlurred
                  ? blurNumber(formatted.formattedValue)
                  : formatted.formattedValue}
                <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                  {formatted.unit}
                </TootTipValueUnit>
              </TootTipValue>
            </TootTipRow>
          );
        })}
        {otherData.map((point, idx) => {
          const formatted = formatTooltipValue(point.data.y);
          const seriesColor = getSeriesColor(point.serieId);
          return (
            <TootTipOtherRow key={idx} margin={bigSize}>
              <TootTipLabel>
                <SquareBox size={bigSize} color={seriesColor} />
                - {point.serieId}
              </TootTipLabel>
              <TootTipValue>
                {isBlurred
                  ? blurNumber(formatted.formattedValue)
                  : formatted.formattedValue}
                <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                  {formatted.unit}
                </TootTipValueUnit>
              </TootTipValue>
            </TootTipOtherRow>
          );
        })}
      </TootTipWrapper>
    );
  };

  const { barWidth, barPadding } = calculateBarWidth(
    wrapperWidth,
    transformedData.length,
    paddingRatio
  );
  return (
    <Wrapper ref={wrapperRef}>
      <PositionWrapper>
        <GraphWrapper isBlurred={isBlurred}>
          <ResponsiveBar
            data={transformedData}
            keys={["value"]}
            indexBy="year"
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            padding={paddingRatio}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={({ data }) => data.color}
            borderColor={{
              from: "color",
              modifiers: [["darker", "0.1"]],
            }}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: "#333333",
                    strokeWidth: 1.2,
                  },
                },
              },
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 12, 
              tickRotation: 0,
              legendOffset: 40, 
              legendPosition: 'middle',
              renderTick: ({ value, x, y }) => (
                <text
                  x={x}
                  y={y + 20}  // Position below grid line
                  textAnchor="middle"
                  style={{
                    fill: '#666',
                    fontSize: 11,
                    fontFamily: 'inherit',
                  }}
                >
                  {value}
                </text>
              )
              }}
              axisLeft={{
              tickSize: 5,
              tickPadding: 12,
              tickRotation: 0,
              tickValues: 5,
              legend: yAxisUnit,
              legendOffset: -52,
              legendPosition: "middle",
              format: (value) => {
                let displayValue;
                if (isMnScale) {
                  displayValue = value / 1_000_000;
                } else if (isCrScale) {
                  displayValue = value / 10_000_000;
                } else {
                  displayValue = value;
                }
                return displayValue;
              },
              renderTick: ({ value, x, y }) => {
                let displayValue;
                if (isMnScale) {
                  displayValue = value / 1_000_000;
                } else if (isCrScale) {
                  displayValue = value / 10_000_000;
                } else {
                  displayValue = value;
                }
                return (
                  <text
                    x={x - 10}  
                    y={y}
                    textAnchor="end"
                    style={{
                      fill: '#666',
                      fontSize: 11,
                      fontFamily: 'inherit',
                    }}
                  >
                    {displayValue.toLocaleString()}
                  </text>
                );
              }
            }}
            enableGridY={false}
            totalsOffset={0}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", "1.6"]],
            }}
            legends={[]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            enableLabel={false}
            barAriaLabel={(e) =>
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            }
            isInteractive={false}
            //   tooltip={CustomTooltip}
            layers={["grid", "axes", "bars", "markers", "legends"]}
          />
        </GraphWrapper>
        <GraphWrapper2 isBlurred={isBlurred}>
          <ResponsiveLine
            data={transformedData2}
            colors={({ serie }) => serie?.color} 
            margin={{
              top: marginTop,
              right:
                marginRight -
                barWidth / 2 +
                (wrapperWidth -
                  transformedData.length * barWidth -
                  (transformedData.length + 1) * barPadding) /
                2,
              bottom: marginBottom,
              left:
                marginLeft -
                barWidth / 2 +
                (wrapperWidth -
                  transformedData.length * barWidth -
                  (transformedData.length + 1) * barPadding) /
                2,
            }}
            theme={{
              background: "#ffffff00",
              text: {
                fontSize: fontSize,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
              tooltip: {
                wrapper: {},
                container: {
                  background: "#ffffff",
                  color: "#333333",
                  fontSize: fontSize,
                },
                basic: {},
                chip: { height: bigSize, width: bigSize },
                table: {},
                tableCell: {},
                tableCellValue: {},
              },
            }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat={(value) => {
              const formatted = `${Number(value.toFixed(2)).toLocaleString(formatCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`;
              return isBlurred ? blurNumber(formatted) : formatted;
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={false}
            enableGridX={false}
            enableGridY={false}
            lineWidth={0}
            enablePoints={false}
            pointSize={smallSize}
            pointColor={{ from: "color", modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "color", modifiers: [] }}
            enablePointLabel={false}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            areaOpacity={0}
            enableSlices="x"
            enableCrosshair={true}
            enableTouchCrosshair={true}
            useMesh={false}
            legends={[]}
            motionConfig="default"
            layers={[
              "grid",
              "markers",
              "areas",
              "lines",
              "slices",
              "points",
              "axes",
              "crosshair",
              CustomLegendLayer,
            ]}
            sliceTooltip={CustomTooltip}
          />
        </GraphWrapper2>
        <LeftCover width={marginLeft + barPadding / 2 + 2} />
        <RightCover width={marginRight + barPadding / 2 + 2} />
      </PositionWrapper>
    </Wrapper>
  );
};

export default TransformedNivoBarGraph;
const TootTipWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 8px;
    border: 0.5px solid #cccccc;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    font-size: ${(props) => props.fontSize}px;
 `;
  
  const TootTipRow = styled.div`
    width: 100%;
    display: flex;
    padding: 2.5px;
    flex-direction: row;
    justify-content: space-between;
  `;
  
  const TootTipOtherRow = styled.div`
    width: 100%;
    display: flex;
    padding: 2.5px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${(props) => 12.5 + props.margin}px;
  `;
  
  const TootTipLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    font-weight: ${(props) => (props.bold ? "500" : "400")};
  `;
  
  const SquareBox = styled.div`
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    background-color: ${(props) => props.color};
    margin-right: 10px;
    border: ${(props) =>
      props.bold ? "1.5px solid #262e40" : "0px solid #262e40"};
  `;
  
  const TootTipValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: ${(props) => (props.bold ? "500" : "400")};
  `;
  
  const TootTipValueUnit = styled.div`
    font-size: ${(props) => props.fontSize}px;
    margin-left: 3px;
    color: #a0a6a9;
    font-weight: 350;
    font-weight: ${(props) => (props.bold ? "500" : "350")};
  `;
  
  const GraphWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  `;
  
  const GraphWrapper2 = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: all;
  `;
  
  const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
  `;

  const PositionWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const LeftCover = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: 100%;
  top: 0;
  left: 0;
`;

const RightCover = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: 100%;
  top: 0;
  right: 0;
`;