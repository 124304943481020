import styled from "styled-components";
import Page from "./Page";
import MarketShare from "./MarketShare";
import KpiWrap from "./KpiWrap/index.js";
import HeadInsightWrap from "./HeadInsightWrap";
import { media } from "../../utils/media";
import { useLocation, useNavigate } from "react-router-dom";

const ReportPages = ({
  maxDate,
  pages = [],
  is_usd = false,
  outerPadding = false,
  colorMappedPlayers,
  dates,
}) => {
  const sortedPage = pages.sort((a, b) => a.page_sequence - b.page_sequence);
  const navigate = useNavigate();
  const currency = window.localStorage.getItem("currency")
    ? window.localStorage.getItem("currency")
    : "INR";

  const conversionType = window.localStorage.getItem("conversion_type")
    ? window.localStorage.getItem("conversion_type")
    : "Dynamic";

  const custUsdExchange =
    conversionType === "Dynamic"
      ? null
      : window.localStorage.getItem("currency_val")
      ? Number(window.localStorage.getItem("currency_val"))
      : 80;

  const exchangeRate = window.localStorage.getItem("EXCHANGE_RATE")
    ? JSON.parse(window.localStorage.getItem("EXCHANGE_RATE"))
    : {};

  const yearPeriod = window.localStorage.getItem("year")
    ? window.localStorage.getItem("year")
    : "CY";
  const search = useLocation().search;
  const isExternal =
    new URLSearchParams(search).get("is_external") === "true" && true;
  const is_admin =
    window.localStorage.getItem("is_admin") === "true" ? true : false;
  return (
    <Wrapper outerPadding={outerPadding}>
      {sortedPage
        .filter((page) => page.element_type === "graph")
        .map((page, idx) => {
          const kpiPage = sortedPage.filter(
            (obj) =>
              obj.element_type === "kpi_card" &&
              obj.page_sequence === page.page_sequence
          );
          if(kpiPage.length && kpiPage[0].data_list.length > 0)
          {
            let filteredDataList=null;
          kpiPage[0].data_list=kpiPage[0].data_list.map(item => {
            if (Array.isArray(item.data)) {
            
              if(dates && dates.end_date)
                {
               filteredDataList =  kpiPage[0].data_list.map(item => {
                if (Array.isArray(item.data)) {
                    return {
                        ...item, // Retain other properties of `item`
                        data: item.data.filter(entry => new Date(entry.date) <= new Date(dates.end_date)) // Filter data
                    };
                }
                return item; // If `data` is not an array, return the item as is
            });
              }
            if(dates && dates.start_date){
              if(filteredDataList===null)
                filteredDataList=kpiPage[0].data_list
              
            filteredDataList =  filteredDataList.map(item => {
              if (Array.isArray(item.data)) {
                  return {
                      ...item, // Retain other properties of `item`
                      data: item.data.filter(entry => new Date(entry.date) >= new Date(dates.start_date)) // Filter data
                  };
              }
              return item; // If `data` is not an array, return the item as is
          });
            }

            }
            return item; // If `data` is not an array, return the item as is
        });
        if(filteredDataList!==null)
        kpiPage[0].data_list = filteredDataList;

          }
          let allKpiCards =
            kpiPage.length > 0
              ? kpiPage[0].slicer
                ? kpiPage[0].slicer
                : []
              : [];
          allKpiCards = allKpiCards.filter((slicer) => slicer);
          const visibleKpiCards = allKpiCards.filter(
            (slicer) => !(slicer?.is_hidden || false)
          );
          const isBlurred = isExternal === true && window.localStorage.getItem("loginStatus") !== "true" && idx !== 0;
          return page.page_layout === "radio_rightcard" ? (
            <PageWrapper key={idx}>
              <Wrapper80 margin={false}>
                <ShadowWrap>
                  <ShadowBox
                    style={{
                      // filter: isBlurred && "blur(10px)",
                      position: "relative",
                    }}
                  >
                    <Page
                      page={page}
                      is_usd={currency === "USD"}
                      custUsdExchange={custUsdExchange}
                      exchangeRate={exchangeRate}
                      yearPeriod={yearPeriod}
                      is_admin={is_admin}
                      colorMappedPlayers={colorMappedPlayers}
                      maxDate={maxDate}
                      isBlurred={isBlurred}
                      dates={dates}
                    />
                  </ShadowBox>
                </ShadowWrap>
              </Wrapper80>
              <Wrapper20 margin={true}>
                <ShadowWrap>
                  <ShadowBox 
                  // style={{ filter: isBlurred && "blur(10px)" }}
                  >
                    <KpiWrap
                      is_usd={currency === "USD"}
                      custUsdExchange={custUsdExchange}
                      exchangeRate={exchangeRate}
                      yearPeriod={yearPeriod}
                      currentSlicer={
                        visibleKpiCards.length > 0 ? visibleKpiCards[0] : null
                      }
                      slicerList={allKpiCards}
                      dataList={
                        kpiPage.length > 0
                          ? kpiPage[0].data_list
                            ? kpiPage[0].data_list
                            : []
                          : []
                      }
                      isBlurred={isBlurred}
                      dates={dates}
                    />
                  </ShadowBox>
                </ShadowWrap>
                <ShadowWrap>
                  <ShadowBox 
                  // style={{ filter: isBlurred && "blur(10px)" }}
                  >
                    <KpiWrap
                      is_usd={currency === "USD"}
                      custUsdExchange={custUsdExchange}
                      exchangeRate={exchangeRate}
                      yearPeriod={yearPeriod}
                      currentSlicer={
                        visibleKpiCards.length > 1 ? visibleKpiCards[1] : null
                      }
                      slicerList={allKpiCards}
                      dataList={
                        kpiPage.length > 0
                          ? kpiPage[0].data_list
                            ? kpiPage[0].data_list
                            : []
                          : []
                      }
                      isBlurred={isBlurred}
                      dates={dates}
                    />
                  </ShadowBox>
                </ShadowWrap>
                <ShadowWrap>
                  <ShadowBox 
                  // style={{ filter: isBlurred && "blur(10px)" }}
                  >
                    <KpiWrap
                      is_usd={currency === "USD"}
                      custUsdExchange={custUsdExchange}
                      exchangeRate={exchangeRate}
                      yearPeriod={yearPeriod}
                      currentSlicer={
                        visibleKpiCards.length > 2 ? visibleKpiCards[2] : null
                      }
                      slicerList={allKpiCards}
                      dataList={
                        kpiPage.length > 0
                          ? kpiPage[0].data_list
                            ? kpiPage[0].data_list
                            : []
                          : []
                      }
                      isBlurred={isBlurred}
                      dates={dates}
                    />
                  </ShadowBox>
                </ShadowWrap>
              </Wrapper20>
            </PageWrapper>
          ) : page.page_layout === "standard_leftcard" ? (
            <PageWrapper key={idx}>
              <Wrapper20 margin={false}>
                <ShadowWrap>
                  <ShadowBox>
                    <KpiWrap
                      is_usd={currency === "USD"}
                      custUsdExchange={custUsdExchange}
                      exchangeRate={exchangeRate}
                      yearPeriod={yearPeriod}
                      currentSlicer={
                        visibleKpiCards.length > 0 ? visibleKpiCards[0] : null
                      }
                      slicerList={allKpiCards}
                      dataList={
                        kpiPage.length > 0
                          ? kpiPage[0].data_list
                            ? kpiPage[0].data_list
                            : []
                          : []
                      }
                      isBlurred={isBlurred}
                      dates={dates}
                    />
                  </ShadowBox>
                </ShadowWrap>
                <ShadowWrap>
                  <ShadowBox>
                    <KpiWrap
                      is_usd={currency === "USD"}
                      custUsdExchange={custUsdExchange}
                      exchangeRate={exchangeRate}
                      yearPeriod={yearPeriod}
                      currentSlicer={
                        visibleKpiCards.length > 1 ? visibleKpiCards[1] : null
                      }
                      slicerList={allKpiCards}
                      dataList={
                        kpiPage.length > 0
                          ? kpiPage[0].data_list
                            ? kpiPage[0].data_list
                            : []
                          : []
                      }
                      isBlurred={isBlurred}
                      dates={dates}
                    />
                  </ShadowBox>
                </ShadowWrap>
                <ShadowWrap>
                  <ShadowBox>
                    <KpiWrap
                      is_usd={currency === "USD"}
                      custUsdExchange={custUsdExchange}
                      exchangeRate={exchangeRate}
                      yearPeriod={yearPeriod}
                      currentSlicer={
                        visibleKpiCards.length > 2 ? visibleKpiCards[2] : null
                      }
                      slicerList={allKpiCards}
                      dataList={
                        kpiPage.length > 0
                          ? kpiPage[0].data_list
                            ? kpiPage[0].data_list
                            : []
                          : []
                      }
                      isBlurred={isBlurred}
                      dates={dates}
                    />
                  </ShadowBox>
                </ShadowWrap>
              </Wrapper20>
              <Wrapper80 margin={true}>
                <ShadowWrap>
                  <ShadowBox>
                    <Page
                      page={page}
                      is_usd={currency === "USD"}
                      custUsdExchange={custUsdExchange}
                      exchangeRate={exchangeRate}
                      yearPeriod={yearPeriod}
                      is_admin={is_admin}
                      colorMappedPlayers={colorMappedPlayers}
                      maxDate={maxDate}
                      isBlurred={isBlurred}
                      dates={dates}

                    />
                  </ShadowBox>
                </ShadowWrap>
              </Wrapper80>
            </PageWrapper>
          ) : page.page_layout === "market_share" ? (
            <Wrapper100 key={idx}>
              <ShadowWrap>
                <ShadowBox>
                  <MarketShare
                    page={page}
                    is_usd={currency === "USD"}
                    custUsdExchange={custUsdExchange}
                    exchangeRate={exchangeRate}
                    yearPeriod={yearPeriod}
                    is_admin={is_admin}
                  />
                </ShadowBox>
              </ShadowWrap>
            </Wrapper100>
          ) : (
            <Wrapper100 key={idx}>
              <ShadowWrap>
                <ShadowBox>
                  <Page
                    page={page}
                    is_usd={currency === "USD"}
                    custUsdExchange={custUsdExchange}
                    exchangeRate={exchangeRate}
                    yearPeriod={yearPeriod}
                    is_admin={is_admin}
                    colorMappedPlayers={colorMappedPlayers}
                    maxDate={maxDate}
                    isBlurred={isBlurred}
                    dates={dates}

                  />
                </ShadowBox>
              </ShadowWrap>
            </Wrapper100>
          );
        })}
      {sortedPage.filter((page) => page.element_type === "insight_header")
        .length ? (
        <Wrapper100>
          <ShadowWrap>
            <ShadowBox>
              <HeadInsightWrap
                is_usd={currency === "USD"}
                custUsdExchange={custUsdExchange}
                exchangeRate={exchangeRate}
                yearPeriod={yearPeriod}
                report={
                  sortedPage.filter(
                    (page) => page.element_type === "insight_header"
                  )[0].report || "Category"
                }
                currentSlicer={
                  sortedPage.filter(
                    (page) => page.element_type === "insight_header"
                  )[0].slicer.length > 0
                    ? sortedPage.filter(
                        (page) => page.element_type === "insight_header"
                      )[0].slicer[0]
                    : null
                }
                slicerList={
                  sortedPage.filter(
                    (page) => page.element_type === "insight_header"
                  )[0].slicer
                }
                dataList={
                  sortedPage.filter(
                    (page) => page.element_type === "insight_header"
                  )[0].data_list
                    ? sortedPage.filter(
                        (page) => page.element_type === "insight_header"
                      )[0].data_list
                    : []
                }
              />
            </ShadowBox>
          </ShadowWrap>
        </Wrapper100>
      ) : null}
    </Wrapper>
  );
};

export default ReportPages;

const Wrapper = styled.div`
  width: 100%;
  padding: calc(3.5vw - 5px);
  padding-top: ${(props) => (props.outerPadding ? "15px" : "0px")};
  padding-bottom: ${(props) => (props.outerPadding ? "5px" : "0px")};
  background-color: #f7fcff;
  background-color: #eef9ff;
  ${media.medium`
    padding-top: ${(props) => (props.outerPadding ? "5px" : "0px")};
    padding-bottom: ${(props) => (props.outerPadding ? "5px" : "0px")};
  `}
  ${media.small`
    padding-top: ${(props) => (props.outerPadding ? "5px" : "0px")};
    padding-bottom: ${(props) => (props.outerPadding ? "0px" : "0px")};
  `}
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${media.small`
    flex-direction: column;
  `}
  ${media.medium`
    flex-direction: column;
  `} // background: lightgreen;
`;

const Wrapper100 = styled.div`
  width: 100%;
  margin-bottom: 10px;
  ${media.medium`
    margin-bottom: 10px;
  `}
  ${media.small`
    margin-bottom: 10px;
  `}
`;

const Wrapper80 = styled.div`
  width: 80%;
  margin-bottom: 10px;
  ${media.medium`
    margin-bottom: ${(props) => (props.margin ? "10px" : "0px")};
    width: 100%;
  `}
  ${media.small`
    margin-bottom: ${(props) => (props.margin ? "10px" : "0px")};
    width: 100%;
  `}
`;

const Wrapper20 = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-bottom: 10px;
  ${media.medium`
    flex-direction: row;
    margin-bottom: ${(props) => (props.margin ? "10px" : "0px")};
    width: 100%;
    height: auto;
  `}
  ${media.small`
    flex-direction: row;
    margin-bottom: ${(props) => (props.margin ? "10px" : "0px")};
    width: 100%;
    height: auto;
  `}
`;

const ShadowWrap = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 5px;
`;

const ShadowBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0px #00000040;
`;
const LoginOverlay = styled.div`
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 30px;
  border-radius: 5px;
  text-align: center;
  z-index: 99999;
  ${media.small`
    left: 50%;
    top: 69%;
  `}
`;
