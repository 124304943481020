// store.js
import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from "./counterSlice";
import { chatHistoryReducer, counterReducer, newClientIdsAccessReducer, newexcelReducer, newFileAccessReducer, userReducer } from './slices';
import { newReportAccessReducer } from "./slices";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    newreportaccessdata:newReportAccessReducer,
    newexcelData: newexcelReducer,
    newfilesData:newFileAccessReducer,
    newClientIdData:newClientIdsAccessReducer,
    chatData:chatHistoryReducer
  }
});
