import { ResponsiveLine } from "@nivo/line";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import MasterTable from "../../../components/MasterTable";
import { Pagination } from "antd";
import { media } from "../../../utils/media";

const TableComponent = ({ data }) => {
  const [isScale, setScale] = useState(false);
  const [isCurrency, setCurrency] = useState(false);
  const [title, setTitle] = useState(data?.Title || "");
  const [isUSDPresent, setIsUSDPresent] = useState(false);
  const [usdToINRRate, setUsdToINRRate] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage,setrowsPerPage] = useState(10);
  const is_usd = false;
  const isINR = data?.Title?.includes("INR");
  const isHashTitle = data?.Title?.includes("#");
  const maxValue = Math.max(...data.Values.flatMap((v) => v.Data));
  const isMnScale = (isHashTitle && maxValue >= 1_000_000) ||
  (isUSDPresent && maxValue >= 1_000_000);
  const isCrScale = isINR && maxValue >= 10_000_000 && !isUSDPresent;
  const yAxisUnit = isMnScale ? (isUSDPresent ? "USD Mn" : "Mn") :
   isCrScale ? "INR Cr" :
     data?.Values?.[0]?.Unit || "Y-Axis";
  const currency_unit = isCurrency
    ? isScale
    ? ` (${is_usd ? "USD" : "INR"} ${is_usd ? "Bn" : "Mn"})`
    : ` (${is_usd ? "USD" : "INR"})`
    : isScale
    ? ` (${is_usd ? "Mn" : "Cr"})`
    : ``;
  useEffect(() => {
    if (!data?.Title) return;
    const usdMatch = data.Title.match(/USD=([\d.]+)\s*INR/);
    if (usdMatch) {
      setIsUSDPresent(true);
      setUsdToINRRate(parseFloat(usdMatch[1]));
      if (maxValue >= 1_000_000) {
        setTitle(data.Title.replace(/USD=[\d.]+ INR/, `USD=${usdMatch[1]} INR (USD Mn)`));
        setScale(true);
      } else {
        setTitle(data.Title);
      }
    } else {
      setTitle(data.Title);
    }
  }, [data]);
  const formatValue = (value) => {
    let scaledValue, formattedValue, unit;
    
    if (isMnScale) {
      scaledValue = value / 1_000_000;
      if (isUSDPresent) {
        unit = " Mn";
        formattedValue = `$${scaledValue.toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      } else {
        unit = " Mn";
        formattedValue = scaledValue.toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    } 
    else if (isCrScale) {
      scaledValue = value / 10_000_000;
      unit = " Cr";
      formattedValue = `₹${scaledValue.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    } 
    else {
      formattedValue = value.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      unit = "";
    }
    
    return formattedValue + unit;
  };
  const normalizedData = useMemo(() => {
    if (!data) return null;
    
    // For combined charts, merge all values
    if (data.isCombined && Array.isArray(data.charts)) {
      return {
        ...data.charts[0],
        Values: data.charts.flatMap(chart => chart.Values),
        Title: "Combined Data View"
      };
    }
    return data;
  }, [data]);

  // Data processing
  const totalLength = normalizedData?.Labels?.Data?.length || 0;
  const currentData = useMemo(() => {
    return normalizedData?.Labels?.Data?.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    ) || [];
  }, [normalizedData, currentPage, rowsPerPage]);

  // Column configuration
  const columnsData = useMemo(() => {
    if (!normalizedData?.Values) return [];
    
    const baseColumns = [{
      key: "period",
      value: <ColumnText>Period</ColumnText>,
      sorting: false,
      min_width: "100px",
      fontSize: "16px",
      show: currentData.some(item => /\d{4}-\d{2}-\d{2}/.test(item) || /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/i.test(item))
    }];
    
    return [
      ...baseColumns,
      ...normalizedData.Values.map(series => ({
        key: series.Legend,
        value: <ColumnText>{series.Legend}</ColumnText>,
        sorting: false,
        min_width: "120px",
        fontSize: "16px"
      }))
    ];
  }, [normalizedData, currentData]);

  // Row data preparation
  const styledRowsData = useMemo(() => {
    if (!normalizedData?.Values || !normalizedData.Labels?.Data) return [];
    
    return currentData.map((date, index) => {
      const originalIndex = (currentPage - 1) * rowsPerPage + index;
      const rowData = {
        period: {
          value: <TextCell>{date}</TextCell>
        }
      };
      
      normalizedData.Values.forEach(series => {
        rowData[series.Legend] = {
          value: <TextCell>{series.Data[originalIndex]}</TextCell>
        };
      });

      return rowData;
    });
  }, [currentData, currentPage, normalizedData, rowsPerPage]);
  const PaginationControls = () => {
   
    if (totalLength <= 10) return null;    
    return (
      <div style={{ 
        marginTop: '16px',
        position: 'relative',
        zIndex: 10
      }}>
        <PaginationContainer>
        <Pagination
            current={currentPage}
            pageSize={rowsPerPage}
            total={totalLength}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setrowsPerPage(pageSize);
            }}
            showSizeChanger
            pageSizeOptions={['10', '20', '50', '100']}
          />
        </PaginationContainer>
      </div>
    );
  };
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '0 auto' ,
    }}>
       <div style={{ 
        borderRadius: '8px',
        padding: '16px'
      }}>
        <MasterTable
          columnsData={columnsData}
          rowsData={styledRowsData}
          headerBackground="#FFFFFF"
          autoCellHeight={true}
          toolGap={-4}
          loading={false}
        />
      </div>
      <PaginationControls />
    </div>
  );
};

export default TableComponent;

const ColumnText = styled.div`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: 500;
  color: #333;
`;

const TextCell = styled.div`
  font-weight: 400;
  color: #333;
  font-size: 14px;
  padding: 4px 0;
  line-height: 1.4;
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  position: 'relative';
  zIndex: 10;
  cursor:pointer;
  .ant-pagination {
    font-size: 14px;
  }
  
  ${media.small`
    .ant-pagination-item,
    .ant-pagination-options {
      margin: 0 2px;
    }
  `}
`;