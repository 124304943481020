import React from "react";
import styled from "styled-components";
import NivoLineGraph from "../../../NivoLineGraph";
import NivoRibbonGraph from "../../../NivoRibbonGraph";
import NivoHundred from "../../../NivoHundred";
import NivoBarGraph from "../../../NivoBarGraph";
import { media } from "../../../../utils/media";
import { IMAGES } from "../../../../constants/constants";
import { useNavigate } from "react-router-dom";
import NivoStackBar from "../../../NivoStackBar";

const CombinedGraph = ({
  data,
  graphType,
  showlegend,
  topOption,
  allOption,
  legendOther,
  legendFilter,
  scaleValue,
  scaleLabel,
  formatCode = "hi",
  frequency = "Y",
  is_percent = false,
  currency_unit,
  includeOverall,
  dataType,
  colorMappedPlayers,
  is_platform=false,
  overallMaxDate,
  isBlurred,
  hideShowOthers
}) => {
  const navigate = useNavigate();
  return data.length && graphType ? (
    <>
      <GraphDesktop>
        <NivoLineGraph
          visible={graphType === "line_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 7.5 : 0}
          fontSize={11}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}
        />
        <NivoBarGraph
          visible={graphType === "bar_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 7.5 : 0}
          fontSize={11}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}

        />
        <NivoRibbonGraph
          visible={graphType === "ribbon_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 7.5 : 0}
          fontSize={11}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}

        />
        <NivoHundred
          visible={graphType === "hundred_ribbon_graph" || dataType==="% Share"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 7.5 : 0}
          fontSize={11}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}

        />
        <NivoStackBar
          visible={graphType === "stack_bar_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 7.5 : 0}
          fontSize={11}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}

        />
        {legendFilter && <LegendHeading>{legendFilter.name}</LegendHeading>}
      </GraphDesktop>
      <GraphMobile>
        <NivoLineGraph
          visible={graphType === "line_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 4 : 0}
          tickRotation={90}
          marginTop={70}
          marginRight={15}
          marginBottom={70}
          marginLeft={15}
          fontSize={7}
          legendDirection="column"
          legendX={30}
          bigSize={8}
          smallSize={3}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}

        />
        <NivoBarGraph
          visible={graphType === "bar_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 4 : 0}
          tickRotation={90}
          marginTop={70}
          marginRight={15}
          marginBottom={70}
          marginLeft={15}
          fontSize={7}
          legendDirection="column"
          legendX={30}
          bigSize={8}
          smallSize={3}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}

        />
        <NivoRibbonGraph
          visible={graphType === "ribbon_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 4 : 0}
          tickRotation={90}
          marginTop={70}
          marginRight={15}
          marginBottom={70}
          marginLeft={15}
          fontSize={7}
          legendDirection="column"
          legendX={30}
          bigSize={8}
          smallSize={3}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}

        />
        <NivoHundred
          visible={graphType === "hundred_ribbon_graph" || dataType==="% Share"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 4 : 0}
          tickRotation={90}
          marginTop={70}
          marginRight={15}
          marginBottom={70}
          marginLeft={15}
          fontSize={7}
          legendDirection="column"
          legendX={30}
          bigSize={8}
          smallSize={3}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}

        />
        <NivoStackBar
          visible={graphType === "stack_bar_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 4 : 0}
          tickRotation={90}
          marginTop={70}
          marginRight={15}
          marginBottom={70}
          marginLeft={15}
          fontSize={7}
          legendDirection="column"
          legendX={30}
          bigSize={8}
          smallSize={3}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
          colorMappedPlayers={colorMappedPlayers}
          is_platform={is_platform}
          overallMaxDate={overallMaxDate}
          isBlurred={isBlurred}
          hideShowOthers={hideShowOthers}

        />
        {legendFilter && <LegendHeading>{legendFilter.name}</LegendHeading>}
      </GraphMobile>
    </>
  ) : (
    <div style={{
      position:"relative"
    }}>
    {isBlurred === true && (
      <LoginOverlay>
        <LoginButton
          onClick={() => {
            navigate("/");
          }}
        >
          Sign in
        </LoginButton>
      </LoginOverlay>
    )}
    <NoDataMessage isBlurred={isBlurred}>
      <TechIssueIcon src={IMAGES.technical_issue} />
    </NoDataMessage>
    </div>
  );
};

export default CombinedGraph;

const GraphDesktop = styled.div`
  width: 100%;
  height: 250px;
  min-height: 25vw;
  position: relative;
  ${media.small`
    display: none;
  `}
`;

const GraphMobile = styled.div`
  width: 100%;
  height: 300px;
  min-height: 25vw;
  position: relative;
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;  
  `}
  ${media.xlarge`
    display: none;  
  `}
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px 10px;
  filter: ${props => props.isBlurred ? 'blur(4px)' : 'none'};
  user-select: ${props => props.isBlurred ? 'none' : 'auto'};
`;

const LegendHeading = styled.div`
  font-size: 13px;
  font-weight: 500;
  height: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  ${media.small`
    font-size: 8px;
    height: 8px;
  `}
`;

const TechIssueIcon = styled.img`
  width: 300px;
  height: 250px;
`;
const LoginOverlay = styled.div`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 30px;
  border-radius: 5px;
  text-align: center;
  z-index: 99999;
  ${media.small`
    left: 50%;
    top: 36%;
  `}
`;

const LoginButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 20px;
  &:hover {
    background-color: #0056b3;
  }
  ${media.small`
   font-size:14px;
  `}
`;

