import styled from "styled-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import InvitationPage from "./InvitationPage";
import { HOME_URL } from "../../constants/constants";
import { media } from "../../utils/media";
import Footer from "../../components/Footer";

const Invitations = (parent_props) => {
  const [selectedpage, setSelectedPage] = useState("Send Invitations");
  const [invitationValues, setInvitationValues] = useState([]);
  const [treeziparr, setTreeziparr] = useState([
    { key: 1, name: "Home" },
    { key: 2, name: "Send Invitations" },
  ]);
  const navigate = useNavigate();

  let handleClickTree = (index) => {
    if (treeziparr[index].key == 1) {
      navigate(HOME_URL)
    }
  };

  return (
    <div>
      <Header />
      <div style={{ backgroundColor: "#FFFFFF", minHeight: "10vh" }}>
        <Header1>
          <LHSTitlediv>{selectedpage}</LHSTitlediv>
          <RHSTitlediv></RHSTitlediv>
        </Header1>
        <div
          style={{
            marginLeft: "3.5vw",
            marginBottom: "10px",
            color: "grey",
          }}
        >
          <>
            {treeziparr?.map((obj, i) => (
              <BreadCrumbSpan
                onClick={(e) => {
                  handleClickTree(i, obj.key);
                }}
                key={i}
              >
                {obj.name} /{" "}
              </BreadCrumbSpan>
            ))}
          </>
        </div>
      </div>
      <PageContent invitationValues={invitationValues}>
        <InvitationPage setInvitationValues={setInvitationValues} invitationValues={invitationValues}/>
      </PageContent>
      <Footer />
    </div>
  );
};

export default Invitations;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f7fcff;
  height: ${(props) => (props.invitationValues?.length > 0 ? "100%" : "73vh")};
  justify-content: center; 
  align-items: center; 
  ${media.small`
      height: ${(props) => (props?.invitationValues?.length > 0 ? "100%" : "76vh")};
justify-content: center; 
  align-items: center; 
  `}
`;

const Header1 = styled.div`
  background-color: white;
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const LHSTitlediv = styled.div`
  font-weight: 500;
  font-size: 30px;
  ${media.small`
      font-size: 15px;

    `}
`;

const RHSTitlediv = styled.div`
  display: flex;
  gap: 10px;
`;

const BreadCrumbSpan = styled.span`
  &:hover {
    color: #20a6ff;
    cursor: pointer;
  }
    ${media.small`
      
      font-size:12px;
      `}
`;
