import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BM_ENDPOINT}`,
  timeout: 1000000,
});


// Add a request interceptor
instance.interceptors.request.use(config => {
    // Do something before request is sent, e.g., set auth token
    config.headers.Authorization = 'Bearer YOUR_TOKEN';
    return config;
  }, error => {
    return Promise.reject(error);
  });
  
  // Add a response interceptor
  instance.interceptors.response.use(response => {
    // Any status code that lies within the range of 2xx causes this function to trigger
    return response.data;
  }, error => {
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    if (error.response.status === 401) {
        // Handle unauthorized error globally, e.g., redirect to login
      }
      if (error.response?.status === 404) {
        console.warn("Received 404 - returning empty response.");
        return Promise.resolve([]); // Resolve with an empty array instead of rejecting
      }
    return Promise.reject(error);
  });
  

export default instance;
