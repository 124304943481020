import React, { useState, useEffect, useRef } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import styled from "styled-components";
import { media } from "../../utils/media";
import { useLocation, useNavigate } from "react-router-dom";
const myColors = [
  "#0099FF",
  "#A962FF", 
  "#26CDCD", 
  "#9FC51E", 
  "#FF832B",
  "#FCBB53",
  "#21AF4A",
  "#66C2FF",
  "#4673FF",
  "#F28D8C",
  "#7D7DE8",
  "#AFEEEE",
  "#DA70D6", 
  "#98FB98", 
  "#F58CBD",
  "#FFDD44", 
  "#89CFF0",
  "#FF6F61",
  "#B1EDE8",
  "#C9A0DC",
  "#93DFB8",
  "#FFD700",
  "#F4A460",
  "#ADD8E6",
  "#FFB347",
  "#77DD77",
  "#DDA0DD",
  "#FFC0CB",
  "#FF6961",
  "#B39EB5",
  "#FFB6C1",
];

const addOpacity = (hexColor, opacity) => {
  // Convert hex to RGB
  const hex = hexColor.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const myColorsWithOpacity = myColors.map((color) => addOpacity(color, 0.8));

const calculateBarWidth = (wrapperWidth, numberOfBars, paddingRatio) => {
  let barWidth =
    (wrapperWidth * (1 - paddingRatio)) /
    ((1 - paddingRatio) * numberOfBars + paddingRatio * (numberOfBars + 1));
  let barPadding =
    (wrapperWidth * paddingRatio) /
    ((1 - paddingRatio) * numberOfBars + paddingRatio * (numberOfBars + 1));
  barPadding = Math.floor(barPadding);
  barWidth = barWidth.toFixed(0);
  return { barWidth, barPadding };
};

const formatDate = (dataDate, frequency,maxDate,overallMaxDate) => {
  const dataDateObj = moment(dataDate, "YYYY-MM-DD");
  const dataDateObj2 = dataDateObj.clone().add(1, "year");

  const currentMoment = moment();
  const currentYear = currentMoment.year();
  const currentFinancialYear =
    currentMoment.month() >= 3 ? currentYear : currentYear - 1;

  if (frequency === "M") {
    return dataDateObj.format("MMM'YY");
  } else if (frequency === "Q") {
    const quarter = Math.floor((dataDateObj.month() + 3) / 3);
    let result = "";

    switch (quarter) {
      case 1:
        result = `JFM'${dataDateObj.format("YY")}`;
        break;
      case 2:
        result = `AMJ'${dataDateObj.format("YY")}`;
        break;
      case 3:
        result = `JAS'${dataDateObj.format("YY")}`;
        break;
      case 4:
        result = `OND'${dataDateObj.format("YY")}`;
        break;
      default:
        result = "Invalid Date";
    }
    const currentQuarter = Math.floor((new Date(overallMaxDate).getMonth() + 3) / 3);
    let month=new Date(overallMaxDate).getMonth()

    if (![2, 5, 8, 11].includes(month) && quarter === currentQuarter && (dataDateObj.year() === new Date(overallMaxDate).getFullYear() )) {
      result += "(QTD)";
    }
    return result;
  } else if (frequency === "Y") {
    let result = `CY${dataDateObj.format("YY")}`;
    let month = moment(overallMaxDate).format("MMMM"); // Get month as a string

    if ((dataDateObj.year() === new Date(overallMaxDate).getFullYear() ) && month !== "December") {
      result += "(YTD)";
    }
    return result;
  } else if (frequency === "FY") {
    let result = `FY${dataDateObj2.format("YY")}`;
    if (
      (dataDateObj.month() >= 3 &&
        dataDateObj.year() === currentFinancialYear) ||
      (dataDateObj.month() < 3 &&
        dataDateObj.year() === currentFinancialYear + 1)
    ) {
      result += "(YTD)";
    }
    return result;
  } 
     else if (frequency === "F") {
            let result = `CY${dataDateObj.format("YY")}`;
           let month = moment(overallMaxDate).format("MMMM"); 
               if ((dataDateObj.year() >= new Date(overallMaxDate).getFullYear()) && month !== "December") {
                 result += "(P)";
               }
            return result;
          } 
  else {
    return "Invalid Frequency";
  }
};
const blurNumber = (value, blurChar = "█") => {
  if (value === null || value === undefined) return value;
  
  const valueStr = String(value);
  const blurredValue = blurChar.repeat(valueStr.length);
  
  return blurredValue;
};

const NivoBarGraph = ({
  visible = true,
  data,
  showlegend = false,
  showTotal = false,
  topOption = [],
  allOption = [],
  legendPadding = 50,
  fontSize = 9,
  tickRotation = 0,
  marginTop = 50,
  marginRight = 50,
  marginBottom = 50,
  marginLeft = 50,
  legendDirection = "row",
  legendX = 20,
  bigSize = 15,
  smallSize = 5,
  scaleValue = 1,
  scaleLabel = null,
  formatCode = "hi",
  frequency = "M",
  paddingRatio = 0.5,
  is_percent = false,
  currency_unit,
  thousandScaling = false,
  colorMappedPlayers,
  is_platform,
  overallMaxDate,
  isBlurred,
  hideShowOthers,
}) => {
  const search = useLocation().search;
  const key = new URLSearchParams(search).get("key");
  const [dataLimit, setDataLimit] = useState({
    M: 13,
    Q: 8,
    Y: key=== "731" ? 12:6,
    FY: key=== "731" ? 12:6,
  });
  const [visibility, setVisibility] = useState(true);
  const [transformedData, setTransformedData] = useState([]);
  const [allLabel, setAllLabel] = useState([]);
  const [sumData, setSumData] = useState({});
  const [transformedData2, setTransformedData2] = useState([]);
  const [wrapperWidth, setWrapperWidth] = useState(null);
  const navigate = useNavigate()
  useEffect(() => {
    const allLabel = data.map((item) => item.label);
    setAllLabel(allLabel);
    let allDates = Array.from(
      new Set(data.flatMap((item) => item.data.map((point) => point.date)))
    ).sort();
    allDates = allDates.slice(-dataLimit[frequency]);

    let maxDate = new Date(
      Math.max(
        ...data.flatMap((item) => item.data.map((point) => new Date(point.date)))
      )
    );
    const visibleDatesSet = new Set(allDates);

    const newTransformed = [];
    data.forEach((label_data) => {
        visibleDatesSet.forEach((date) => {
            const point = label_data.data.find((d) => d.date === date);
            if (
                point &&
                (topOption.length === 0 || topOption.includes(label_data.label)) &&
                label_data.label !== "Overall"
            ) {
                const dateId = formatDate(date, frequency, maxDate, overallMaxDate);
                const category = dateId.endsWith("(P)") ? "Forecasted Data" : "Actual Data";
                newTransformed.push({
                    year: dateId,
                    value: point.value,
                    category: category
                });
            }
        });
    });

    // Set the transformed data for use in charts
    setTransformedData(newTransformed);
    const transformed2 = data.map((item) => {
      let dataList = [{ x: "a", y: 0 }];
      allDates.forEach((date) => {
        const point = item.data.find((d) => d.date === date);
        const formattedDate = formatDate(date, frequency,maxDate,overallMaxDate)
        const category = formattedDate.endsWith("(P)") ? "Forecasted Data" : "Actual Data";
        dataList.push({
          x: formattedDate,
          y: point ? point.value : null,
          category:category
        });
      });
      dataList.push({ x: "b", y: 0 });
      return {
        id: item.label,
        data: dataList,
      };
    });
    setTransformedData2(transformed2);
    setVisibility(visible);
  }, [data, dataLimit]);

  const CustomTooltip = ({ slice }) => {
    const reversedData = [...slice.points].reverse();
    const topData = reversedData.filter(
      (obj) =>
        (topOption.length === 0 || topOption.includes(obj.serieId)) &&
        obj.serieId !== "Overall"
    );
    const totalValue = topData.reduce(
      (sum, dataPoint) => sum + dataPoint.data.y,
      0
    );
    const totalValueList = reversedData.filter(
      (obj) => obj.serieId === "Overall"
    );

    const otherData = reversedData.filter(
      (obj) => !topData.includes(obj) && obj.serieId !== "Overall"
    );

    return (
      <TootTipWrapper fontSize={fontSize}>
        {showTotal && totalValueList.length && (
          <TootTipRow>
            <TootTipLabel bold={true}>
              <SquareBox size={bigSize} color={"#cccccc"} bold={true} />
              Overall
            </TootTipLabel>
            <TootTipValue bold={true}>
              {/* {Number(totalValue.toFixed(2)).toLocaleString(formatCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} */}
              {totalValueList[0].data.yFormatted}
              {is_percent ? "%" : ""}
              <TootTipValueUnit fontSize={Number(fontSize * 0.8)} bold={true}>
                {!is_percent ? currency_unit : ""}
              </TootTipValueUnit>
            </TootTipValue>
          </TootTipRow>
        )}
        {topData.map((point, idx) => {
          const squareColor = point.data.x.endsWith("(P)") ? "#ADD8E6" : point.serieColor;
          return (
            <TootTipRow key={idx}>
              <TootTipLabel>
                <SquareBox size={bigSize} color={squareColor} />
                {point.serieId}
              </TootTipLabel>
              <TootTipValue>
                {isBlurred
                  ? blurNumber(point.data.yFormatted)
                  : point.data.yFormatted}
                {is_percent ? "%" : ""}
                <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                  {!is_percent ? currency_unit : ""}
                </TootTipValueUnit>
              </TootTipValue>
            </TootTipRow>
          );
        })}
        {hideShowOthers && otherData.map((point, idx) => (
          <TootTipOtherRow key={idx} margin={bigSize}>
            <TootTipLabel>- {point.serieId}</TootTipLabel>
            <TootTipValue>
              {isBlurred 
                ? blurNumber(point.data.yFormatted)
                : point.data.yFormatted}
              {is_percent ? "%" : ""}
              <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                {!is_percent ? currency_unit : ""}
              </TootTipValueUnit>
            </TootTipValue>
          </TootTipOtherRow>
        ))}
      </TootTipWrapper>
    );
  };

  const LabelLayer = (data) => {
    let decimalCount = 2;
    if (wrapperWidth < 450) {
      decimalCount = 1;
    }
    if (wrapperWidth < 350) {
      decimalCount = 0;
    }
    return (
      <g>
        {data.bars.map((bar) => {
          let legendVisible = false;
          if (bar.data.value && bar.data.value.toFixed(decimalCount)) {
            legendVisible = true;
          }
          const scaleThousand = thousandScaling && bar.data.value > 1000;
          const pointDecimal = scaleThousand
            ? bar.data.value > 10000
              ? 0
              : 1
            : decimalCount;

          const formattedValue = legendVisible 
            ? `${Number(
                (scaleThousand
                  ? bar.data.value / 1000
                  : bar.data.value
                ).toFixed(pointDecimal)
              ).toLocaleString(formatCode, {
                minimumFractionDigits: pointDecimal,
                maximumFractionDigits: pointDecimal,
              })}${scaleThousand ? "K" : ""}${is_percent ? "%" : ""}`
            : null;

          // Apply blur if enabled
          const displayValue = legendVisible && isBlurred 
            ? blurNumber(formattedValue)
            : formattedValue;
          const isProjected = bar?.data?.data?.year?.endsWith("(P)");
          return (
            <text
              key={bar.key}
              x={bar.x + bar.width / 2}
              y={bar.y + bar.height / 2 + fontSize / 2}
              textAnchor="middle"
              style={{
                // fill: "#FFFFFF",
                fill: "#262e40",
                fontSize: fontSize,
                fontWeight: isProjected ? "bold" : "normal",
                // textShadow: "1px 1px 2px rgba(255,255,255,0.7)",
                // textShadow:
                //   "1px 1px 2px rgba(255,255,255,0.7), -1px -1px 2px rgba(255,255,255,0.7)",
                filter: isBlurred ? "blur(10px)" : "none",
              }}
            >
              {displayValue}
            </text>
          );
        })}
      </g>
    );
  };

  const wrapperRef = useRef(null);

  const updateWrapperWidth = () => {
    if (wrapperRef.current) {
      if (wrapperRef.current.offsetWidth)
        setWrapperWidth(
          wrapperRef.current.offsetWidth - marginLeft - marginRight
        );
    }
  };

  useEffect(() => {
    updateWrapperWidth();
    window.addEventListener("resize", updateWrapperWidth);
    return () => {
      window.removeEventListener("resize", updateWrapperWidth);
    };
  }, [wrapperRef, marginLeft, marginRight]);

  useEffect(() => {
    // wrapperRef haves 0 width, when compoent is hidden initially, IntersectionObserver help when it's visible
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            //component enters the viewport
            updateWrapperWidth();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, []);

  const CustomLegendLayer = ({ series }) => {
    const isRow = legendDirection === "row";
    const effectiveMarginLeft =
      marginLeft -
      barWidth / 2 +
      (wrapperWidth -
        transformedData.length * barWidth -
        (transformedData.length + 1) * barPadding) /
        2;

    let xPosition = legendPadding - effectiveMarginLeft;
    let yPosition = -marginTop + bigSize / 2;
    return (
      <g>
        {showlegend &&
          series.map((item, index) => {
            const xCurrentPosition = xPosition;
            xPosition = (item.id.length * fontSize) / 2 + xPosition + 30;
            const yCurrentPosition = yPosition;
            yPosition = yPosition + 12;
            return (
              <g
                key={item.id}
                transform={
                  isRow
                    ? `translate(${xCurrentPosition},${
                        -marginTop + bigSize / 2
                      })`
                    : `translate(${
                        legendPadding - effectiveMarginLeft
                      },${yCurrentPosition})`
                }
              >
                <circle cx={10} cy={0} r={bigSize / 2} fill={item.color} />
                <text
                  x={20}
                  y={0}
                  textAnchor="start"
                  alignmentBaseline="middle"
                  fill="#000000"
                  fontSize={fontSize}
                >
                  {item.id}
                </text>
              </g>
            );
          })}
      </g>
    );
  };

  const { barWidth, barPadding } = calculateBarWidth(
    wrapperWidth,
    transformedData.length,
    paddingRatio
  );
  const CustomTooltipBar = ({ id, value, color, x, y, width, height }) => {
    const tooltipStyle = {
      position: "absolute",
      top: `${y - 40}px`,
      left: `${x + width / 2 - 50}px`,
      background: color,
      color: "#333333",
      padding: "10px",
      borderRadius: "4px",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
      zIndex: 1000,
      textAlign: "center",
      minWidth: "100px",
      transition: "opacity 0.2s ease-in-out, transform 0.2s ease-in-out",
    };

    return (
      <div style={tooltipStyle}>
        <strong>{id}</strong>: {value}
      </div>
    );
  };

  return (
    <Wrapper ref={wrapperRef} visible={visibility}>
      <PositionWrapper>
      {isBlurred === true && (
                    <LoginOverlay>
                      <LoginButton
                        onClick={() => {
                          navigate("/signin3");
                        }}
                      >
                        Sign in
                      </LoginButton>
                    </LoginOverlay>
                  )}
        <GraphWrapper isBlurred={isBlurred}>
        <ResponsiveBar
            data={transformedData}
            keys={["value"]}
            indexBy="year"
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            tooltip={CustomTooltipBar}
            padding={paddingRatio}
            //   width={0.5}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={({ data }) => (data.category === "Forecasted Data" ? "url(#patternLines)":data.category === "Actual Data"?"#0099FF" :  is_platform
              ? (series) => {
                  if (!colorMappedPlayers || !colorMappedPlayers[series.id]) {
                    console.warn(`Color mapping missing for series: ${series.id}`);
                    return "#FAEBD7"; // Default color if mapping is missing
                  }
                  return addOpacity(colorMappedPlayers[series.id],0.8);
                }
              : myColorsWithOpacity)}
            borderColor={{
              from: "color",
              modifiers: [["darker", "0.1"]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: tickRotation,
              legend: null,
              legendOffset: 36,
              legendPosition: "middle",
              truncateTickAt: 0,
            }}
            axisLeft={null}
            enableGridY={false}
            totalsOffset={0}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", "1.6"]],
            }}
            // legends={
            //   showlegend
            //     ? [
            //         {
            //           anchor: "top-left",
            //           itemDirection: "left-to-right",
            //           direction: legendDirection,
            //           justify: false,
            //           translateX: legendX,
            //           translateY: -marginTop,
            //           itemWidth: 80,
            //           itemHeight: bigSize,
            //           itemsSpacing: 5,
            //           symbolSize: bigSize,
            //           symbolShape: "circle",
            //           itemTextColor: "#777",
            //           effects: [
            //             {
            //               on: "hover",
            //               style: {
            //                 itemBackground: "rgba(0, 0, 0, .03)",
            //                 itemOpacity: 1,
            //               },
            //             },
            //           ],
            //           titleAlign: "start",
            //           titleOffset: 4,
            //         },
            //       ]
            //     : []
            // }
            legends={[]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            enableLabel={false}
            barAriaLabel={(e) =>
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            }
            isInteractive={false}
            //   tooltip={CustomTooltip}
            layers={["grid", "axes", "bars", "markers", "legends", LabelLayer]}
            defs={[
              {
                id: "patternLines",
                type: "patternLines",
                background: "#C5E4F3",
                color: "#ADD8E6",
                rotation: -45,
                lineWidth: 4,
                spacing: 8,
              },
            ]}
            fill={[
              {
                match: (d) => d.category === "Forecasted Data",
                id: "patternLines",
              },
            ]}
          />
        </GraphWrapper>
        <GraphWrapper2 isBlurred={isBlurred}>
          <ResponsiveLine
            data={transformedData2}
            colors={
              is_platform
                ? (series) => {
                    if (!colorMappedPlayers || !colorMappedPlayers[series.id]) {
                      console.warn(`Color mapping missing for series: ${series.id}`);
                      return "#FAEBD7"; // Default color if mapping is missing
                    }
                    return colorMappedPlayers[series.id];
                  }
                : myColors
            }
            margin={{
              top: marginTop,
              right:
                marginRight -
                barWidth / 2 +
                (wrapperWidth -
                  transformedData.length * barWidth -
                  (transformedData.length + 1) * barPadding) /
                  2,
              bottom: marginBottom,
              left:
                marginLeft -
                barWidth / 2 +
                (wrapperWidth -
                  transformedData.length * barWidth -
                  (transformedData.length + 1) * barPadding) /
                  2,
            }}
            theme={{
              background: "#ffffff00",
              text: {
                fontSize: fontSize,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
              tooltip: {
                wrapper: {},
                container: {
                  background: "#ffffff",
                  color: "#333333",
                  fontSize: fontSize,
                },
                basic: {},
                chip: { height: bigSize, width: bigSize },
                table: {},
                tableCell: {},
                tableCellValue: {},
              },
            }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat={(value) => {
              const formatted = `${Number(value.toFixed(2)).toLocaleString(formatCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`;
              return isBlurred ? blurNumber(formatted) : formatted;
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null} //this change
            axisLeft={false}
            enableGridX={false}
            enableGridY={false}
            lineWidth={0} //this change
            enablePoints={false} //this change
            pointSize={smallSize}
            pointColor={{ from: "color", modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "color", modifiers: [] }}
            enablePointLabel={false}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            areaOpacity={0} //this change
            enableSlices="x"
            enableCrosshair={true}
            enableTouchCrosshair={true}
            useMesh={false}
            legends={[]}
            motionConfig="default"
            layers={[
              "grid",
              "markers",
              "areas",
              "lines",
              "slices",
              "points",
              "axes",
              "crosshair",
              CustomLegendLayer,
            ]}
            sliceTooltip={CustomTooltip}
          />
        </GraphWrapper2>
        <LeftCover width={marginLeft + barPadding / 2 + 2} />
        <RightCover width={marginRight + barPadding / 2 + 2} />
      </PositionWrapper>
    </Wrapper>
  );
};

export default NivoBarGraph;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
`;

const PositionWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const GraphWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: ${props => props.isBlurred ? 'blur(4px)' : 'none'};
  user-select: ${props => props.isBlurred ? 'none' : 'auto'};

`;

const GraphWrapper2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: ${props => props.isBlurred ? 'blur(4px)' : 'none'};
  user-select: ${props => props.isBlurred ? 'none' : 'auto'};
`;

const LeftCover = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: 100%;
  top: 0;
  left: 0;
`;

const RightCover = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: 100%;
  top: 0;
  right: 0;
`;

const TootTipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 8px;
  border: 0.5px solid #cccccc;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  font-size: ${(props) => props.fontSize}px;
`;

const TootTipRow = styled.div`
  width: 100%;
  display: flex;
  padding: 2.5px;
  flex-direction: row;
  justify-content: space-between;
`;

const TootTipOtherRow = styled.div`
  width: 100%;
  display: flex;
  padding: 2.5px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: ${(props) => 12.5 + props.margin}px;
`;

const TootTipLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  font-weight: ${(props) => (props.bold ? "500" : "400")};
`;

const SquareBox = styled.div`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  border: ${(props) =>
    props.bold ? "1.5px solid #262e40" : "0px solid #262e40"};
`;

const TootTipValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${(props) => (props.bold ? "500" : "400")};
`;

const TootTipValueUnit = styled.div`
  font-size: ${(props) => props.fontSize}px;
  margin-left: 3px;
  color: #a0a6a9;
  font-weight: 350;
  font-weight: ${(props) => (props.bold ? "500" : "350")};
`;

const LoginOverlay = styled.div`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 30px;
  border-radius: 5px;
  text-align: center;
  z-index: 99999;
  ${media.small`
    left: 50%;
    top: 36%;
  `}
`;

const LoginButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 20px;
  &:hover {
    background-color: #0056b3;
  }
  ${media.small`
   font-size:14px;
  `}
`;