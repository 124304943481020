import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { media } from "../../utils/media";
import axios from "axios";
import moment from "moment";
import { PARAMETER_GROUP_ENDPOINT } from "../../constants/constants";
import { onCompanyProfileClickCT } from "../../utils/clevertap";
import Loader from "../Loader";
import { Editor } from "@tinymce/tinymce-react";
import { FiPlusCircle, FiSave } from "react-icons/fi";
import { MdClose, MdEdit } from "react-icons/md";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import QuillEditor from "../EditorQuill";

const TabbedHighlights = ({ reportId, industry_id, isModalOpen, setIsModalOpen }) => {
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [tabs, setTabs] = useState([]);
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [editorContent, setEditorContent] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [modaleditorContent, setmodalEditorContent] = useState("");
  const [modalselectedDate, setmodalSelectedDate] = useState("");
  const [availableDates, setAvailableDates] = useState([]);
  const [buttonState, setButtonState] = useState("default");
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const is_athena = process.env.REACT_APP_API_ENDPOINT.includes("athena");
  const is_admin =
    window.localStorage.getItem("is_admin") === "true" ? true : false;
  const [isHighLightsAvailable, setIsHighLightsAvailable] = useState(true);
  const [selectedTab, setSelectedTab] = useState("highlights");
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const tabDefinitions = [
    { id: 'highlights', label: 'HIGHLIGHTS', hasMultipleDates: true },
    { id: 'market_definition', label: 'MARKET DEFINITION', hasMultipleDates: false },
    { id: 'methodology', label: 'METHODOLOGY', hasMultipleDates: false },
    { id: 'metrics_covered', label: 'METRICS COVERED', hasMultipleDates: false }
  ];

  // const modules = {
  //   toolbar: [
  //     [{ 'header': '1'}, {'header': '2'}, { 'font': ['arial', 'serif', 'monospace'] }],
  //     [{ 'size': ['small', false, 'large', 'huge'] }],
  //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  //     [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
  //     ['link', 'image', 'video'],
  //     ['clean'] 
  //   ],
  // };

  // const formats = [
  //   'header', 'font', 'size',
  //   'bold', 'italic', 'underline', 'strike', 'blockquote',
  //   'list', 'bullet', 'indent',
  //   'link', 'image', 'video'
  // ]; 
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/tabbed-highlights-new/?report_id=${reportId}`
      );
      const result = await response.json();
  
      // Extract available dates
      const dates = Object.keys(result);
      if (dates && dates[0] === "error") setIsHighLightsAvailable(false);
      setAvailableDates(dates);
      if (dates.length > 0) {
        setSelectedDate(dates[0]);
      }
      setData(result);
  
      // Filter available tabs
      const allTabs = tabDefinitions.map(tab => tab.id);
      const filteredTabs = allTabs.filter(tab => 
        dates.some(date => result[date] && result[date][tab] !== undefined && result[date][tab] !== "")
      );
  
      // Add "coverage" if not already included
      if (!filteredTabs.includes("coverage") && allCompanies.length !== 0) {
        filteredTabs.push("coverage");
      }
  
      setTabs(filteredTabs);
  
      // Set the first valid tab as active and initialize editor content
      if (filteredTabs.length > 0) {
        const firstTab = filteredTabs[0];
        setActiveTab(firstTab);
        const tabDef = tabDefinitions.find(t => t.id === firstTab);
        setShowDateDropdown(tabDef ? tabDef.hasMultipleDates : false);
  
        let content = "";
        let selectedDateForTab = dates[0]; // Default to the first available date
  
        if (result[selectedDateForTab]?.[firstTab]) {
          content = result[selectedDateForTab][firstTab];
        } else {
          // Find the first date where content is available
          const dateWithContent = dates.find(date => result[date] && result[date][firstTab]);
          if (dateWithContent) {
            selectedDateForTab = dateWithContent;
            content = result[dateWithContent][firstTab] || "";
          }
        }
  
        setSelectedDate(selectedDateForTab);
        setEditorContent(content);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsHighLightsAvailable(false);
    }
  };
 
  useEffect(() => {
    fetchData();
  }, [reportId,allCompanies]);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        PARAMETER_GROUP_ENDPOINT,
        {
          table_type: "iil_highlight_listing",
          industry_id: industry_id,
          profile: "overall",
          data_category: "overall",
          data_frequency: "monthly",
          end_date: moment(moment().startOf("month")).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;

          const industry_id_set = new Set();
          let companiesData = responseData.players_list.map((comp) => {
            if (!industry_id_set.has(comp.industry_id) && comp.player_id) {
              industry_id_set.add(comp.industry_id);
            }
            return {
              ...comp,
            };
          });

          setAllCompanies(companiesData);
          setDataLoaded(true);
        } else {
          console.error(
            "Error in fetching parameter group in landscape player listing ",
            response.error.message
          );
        }
      })
      .catch((error) => {
        console.error(
          "Error in fetching parameter group for landscape player listing -",
          error
        );
      });
  }, [industry_id]);

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const toggleReadOnly = () => {
    setIsReadOnly((prevState) => !prevState);
  };
  const editData = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const date = new Date(selectedDate);
      date.setDate(1); 
      const formattedDate = date.toISOString().split("T")[0];
      // if (activeTab === "market_definition") {
      //   setMarketDefinitionContent(editorContent);
      // }
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/tabbed-highlights-new/`,
        {
          report_id: reportId,
          date: formattedDate,
          updates: {
            [activeTab]: editorContent,
          },
        },
        {
          headers: headers,
        }
      );

      fetchData();
      setIsReadOnly(true);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleDateChange = (event) => {
    const selected = event.target.value;
    setSelectedDate(selected);

    if (data[selected] && data[selected][activeTab]) {
      setEditorContent(data[selected][activeTab] || "");
    } else {
      setEditorContent("");
    }
  };

  const handleModalDateChange = (event) => {
    const selected = event.target.value;
    setmodalSelectedDate(selected);
  };

  const handleModalEditorChange = (content) => {
    setmodalEditorContent(content);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setmodalEditorContent("");
    setmodalSelectedDate("");
    setSelectedTab("highlights")
  };
  const saveData = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const date = new Date(modalselectedDate);
      date.setDate(1);
      const formattedDate = date.toISOString().split("T")[0];
      const payload = {
        report_id: reportId,
        date: formattedDate,
        updates: {
          [selectedTab]: modaleditorContent,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/tabbed-highlights-new/`,
        payload,
        {
          headers: headers,
        }
      );
      fetchData();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const updateProdJson = async () => {
    setButtonState("loading");
    try {
      const payload = {
        report_id: reportId,
      };
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/athena-prod-highlight/`,
        payload
      );
      setTimeout(() => setButtonState("default"), 3000);
    } catch (error) {
      console.error("Error updating JSON:", error);
      alert("Failed to update. Check the console for details.");
      setButtonState("default");
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const tabDef = tabDefinitions.find(t => t.id === tab);
    setShowDateDropdown(tabDef ? tabDef.hasMultipleDates : false);
    
    if (tab === "highlights" && data[selectedDate]?.highlights !== undefined) {
      setEditorContent(data[selectedDate]?.highlights || "");
    } else if (tab !== "coverage") {
      const dateWithContent = availableDates.find(date => data[date] && data[date][tab]);
      if (dateWithContent) {
        setEditorContent(data[dateWithContent][tab] || "");
      } else {
        setEditorContent("");
      }
    }
  };

  if (!data) return <div>Loading...</div>;

  if (tabs.length === 0) return <div>No data available</div>;
  const openConfirmModal = () => setConfirmModalVisible(true);
  
  const closeConfirmModal = () => setConfirmModalVisible(false);
  
  const handleConfirm = () => {
    updateProdJson();
    closeConfirmModal();
  };
  const handleTabChange = (event) => {
    setSelectedTab(event.target.value);
  };
  return (
    <OuterWarp>
      <TabContainer>
        <TabButtonsWrapper>
          {tabs.map((tab) => (
            <TabButton
              key={tab}
              onClick={() => handleTabClick(tab)}
              active={activeTab === tab}
            >
              {tabDefinitions.find(t => t.id === tab)?.label || tab.replace("_", " ").toUpperCase()}
            </TabButton>
          ))}
        </TabButtonsWrapper>
        {is_athena && is_admin && isHighLightsAvailable ? (
          <>
            <ExcelButton
              onClick={openConfirmModal}
              disabled={buttonState === "loading"}
            >
            {" "}
            {buttonState === "loading" && "Updating..."}
            {buttonState === "default" && "Push To BM"}
            </ExcelButton>
            {confirmModalVisible && (
              <ConfirmModalContainer>
                <ConfirmModalContent>
                  <ConfirmModalHeader>
                    <CloseButton onClick={closeConfirmModal}>
                      <MdClose size={24} />
                    </CloseButton>
                  </ConfirmModalHeader>
                  <ModalBody>
                    <ModalText>
                      Are you sure you want to push to bm?
                    </ModalText>
                    <ConfirmModalFooter>
                      <ConfirmButton onClick={handleConfirm}>
                        Yes
                      </ConfirmButton>
                      <CancelButton onClick={closeConfirmModal}>
                        No
                      </CancelButton>
                    </ConfirmModalFooter>
               
                  </ModalBody>
                </ConfirmModalContent>
              </ConfirmModalContainer>
            )}
          </>
        ) : null}
        {is_admin && isHighLightsAvailable ? (
          <AddButton onClick={toggleModal}>
            <FiPlusCircle size={30} />
          </AddButton>
        ):<>&nbsp;</>}
        {isHighLightsAvailable && showDateDropdown && activeTab !== "coverage" ? (
          <DropdownWrapper>
            <select
              id="date-select"
              value={selectedDate}
              onChange={handleDateChange}
            >
              {availableDates.map((date) => (
                <option key={date} value={date}>
                  {moment(date).format("MMM YYYY")}
                </option>
              ))}
            </select>
          </DropdownWrapper>
        ) :  <>{activeTab !== "coverage"&& <DropdownWrapper>
       <select disabled>
          <option>
          {moment(
              data[selectedDate]?.[activeTab]
                ? selectedDate
                : availableDates.find(date => data[date]?.[activeTab]) || selectedDate
            ).format("MMM YYYY")}
          </option>
        </select>
      </DropdownWrapper>}
      </>}
      </TabContainer>
      <ContentContainer>
        {activeTab === "coverage" && !isDataLoaded ? (
          <>
            <LoadingMessage>Loading...</LoadingMessage>
            <Loader position="absolute" height="100%" bgColor="#FFFFFF60" />
          </>
        ) : activeTab === "coverage" && (!allCompanies || allCompanies.length !== 0)  ? (
          <ColumnsWrapper>
            {Array.from({ length: 3 }, (_, columnIndex) => (
              <Column key={columnIndex}>
                {allCompanies
                  .filter((_, index) => index % 3 === columnIndex)
                  .map((company, index) => (
                    <PlayerWrap key={index}>
                      {company.icon_url ? (
                        <StyledImage
                          src={company.icon_url}
                          alt="Company Icon"
                        />
                      ) : (
                        <StyledImage
                          src="https://aredstorage.blob.core.windows.net/images/companylogo/100%20x%20100/default_icon.png"
                          alt="Default Company Icon"
                        />
                      )}
                      <CpProfileLink
                        islink={
                          Boolean(IndustryDict[company.company_profile_id])
                            ? "true"
                            : "false"
                        }
                        onClick={() => {
                          if (
                            Boolean(IndustryDict[company.company_profile_id])
                          ) {
                            const url = `/Report3/?val=${
                              IndustryDict[company.company_profile_id]
                                .report_name
                            }&key=${
                              IndustryDict[company.company_profile_id].id
                            }&filter=${
                              IndustryDict[company.company_profile_id].filter
                            }&filter_value=${
                              IndustryDict[company.company_profile_id]
                                .filter_value
                            }`;
                            window.open(url, "_blank");
                          }
                          onCompanyProfileClickCT(
                            IndustryDict[company.company_profile_id]
                              .report_name,
                            IndustryDict[company.company_profile_id].id,
                            IndustryDict[company.sector_profile_id].report_name,
                            IndustryDict[company.sector_profile_id].id
                          );
                        }}
                      >
                        {company.player_name}
                      </CpProfileLink>
                    </PlayerWrap>
                  ))}
              </Column>
            ))}
          </ColumnsWrapper>
        ) : (
          <>
            {is_admin && (
              <EditorHeader>
                {activeTab !== "coverage" && <IconWrapper onClick={toggleReadOnly}>
                  {isReadOnly ? (
                    activeTab !== "coverage" && <MdEdit size={20} />
                  ) : (
                    activeTab !== "coverage" && <FiSave size={20} onClick={editData} />
                  )}
                </IconWrapper>}
              </EditorHeader>
            )}
            {isReadOnly || !is_admin ? (
              <div dangerouslySetInnerHTML={{ __html: editorContent }} />
            ) : (
              // <Editor
              //   apiKey="bcsbuhst7hgy8t1kid3gicdwqtrlaok1m6nml6vbcdomjjir"
              //   init={{
              //     plugins: [
              //       "anchor",
              //       "autolink",
              //       "charmap",
              //       "codesample",
              //       "emoticons",
              //       "image",
              //       "link",
              //       "lists",
              //       "media",
              //       "searchreplace",
              //       "table",
              //       "visualblocks",
              //       "wordcount",
              //       "checklist",
              //       "mediaembed",
              //       "casechange",
              //       "export",
              //       "formatpainter",
              //       "pageembed",
              //       "a11ychecker",
              //       "tinymcespellchecker",
              //       "permanentpen",
              //       "powerpaste",
              //       "advtable",
              //       "advcode",
              //       "editimage",
              //       "advtemplate",
              //       "ai",
              //       "mentions",
              //       "tinycomments",
              //       "tableofcontents",
              //       "footnotes",
              //       "mergetags",
              //       "autocorrect",
              //       "typography",
              //       "inlinecss",
              //       "markdown",
              //       "importword",
              //       "exportword",
              //       "exportpdf",
              //     ],
              //     toolbar:
              //       "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              //     tinycomments_mode: "embedded",
              //     tinycomments_author: "Author name",
              //     mergetags_list: [
              //       { value: "First.Name", title: "First Name" },
              //       { value: "Email", title: "Email" },
              //     ],
              //     ai_request: (request, respondWith) =>
              //       respondWith.string(() =>
              //         Promise.reject("See docs to implement AI Assistant")
              //       ),
              //   }}
              //   value={editorContent}
              //   onEditorChange={handleEditorChange}
              // />
              // <ReactQuill 
              //   value={editorContent}
              //   onChange={handleEditorChange}
              //   modules={modules} 
              //   formats={formats} 
              // />
              <>
                      <div style={{ visibility: activeTab === "coverage" ? 'hidden' : 'visible', height: activeTab === "coverage" ? '0' : 'auto' }}>
                        <QuillEditor
                          value={editorContent}
                          onChange={handleEditorChange}
                        />
                      </div>
                    </>
            )}
          </>
        )}
      </ContentContainer>
      {isModalOpen && (
        <ModalContainer>
          <ModalContent>
            <ModalHeader>
              <h2>Save Highlights</h2>
              <CloseButton onClick={toggleModal}>
                <MdClose size={24} />
              </CloseButton>
            </ModalHeader>
            <ModalBody>
              <DropdownContainer>
                <label htmlFor="tab-selector">Select Tab:</label>
                <select id="tab-selector" value={selectedTab} onChange={handleTabChange}>
                  {tabDefinitions.map((tab) => (
                    <option key={tab.id} value={tab.id}>
                      {tab.label}
                    </option>
                  ))}
                </select>
              </DropdownContainer>
              <DatePicker>
                <label htmlFor="date-picker">Select Date: 

                 <div style={{color:"red"}}> (For only Highlights actual start date of a month is required for others any date works) </div>
                </label>
      
                <input
                  type="date"
                  id="date-picker"
                  value={modalselectedDate}
                  onChange={handleModalDateChange}
                />
              </DatePicker>
              <EditorWrapper>
                <label htmlFor="editor">Editor:</label>
               {/* <Editor
                  apiKey="bcsbuhst7hgy8t1kid3gicdwqtrlaok1m6nml6vbcdomjjir"
                  value={modaleditorContent}
                  onEditorChange={handleModalEditorChange}
                  init={{
                    plugins: [
                      "anchor",
                      "autolink",
                      "charmap",
                      "codesample",
                      "emoticons",
                      "image",
                      "link",
                      "lists",
                      "media",
                      "searchreplace",
                      "table",
                      "visualblocks",
                      "wordcount",
                      "checklist",
                      "mediaembed",
                      "casechange",
                      "export",
                      "formatpainter",
                      "pageembed",
                      "a11ychecker",
                      "tinymcespellchecker",
                      "permanentpen",
                      "powerpaste",
                      "advtable",
                      "advcode",
                      "editimage",
                      "advtemplate",
                      "ai",
                      "mentions",
                      "tinycomments",
                      "tableofcontents",
                      "footnotes",
                      "mergetags",
                      "autocorrect",
                      "typography",
                      "inlinecss",
                      "markdown",
                      "importword",
                      "exportword",
                      "exportpdf",
                    ],
                    toolbar:
                      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    tinycomments_mode: "embedded",
                    tinycomments_author: "Author name",
                    mergetags_list: [
                      { value: "First.Name", title: "First Name" },
                      { value: "Email", title: "Email" },
                    ],
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject("See docs to implement AI Assistant")
                      ),
                  }}
                  style={{
                    maxHeight: "50vh",
                    overflowY: "auto",
                  }}
                /> */}
                {/* <ReactQuill 
                  value={modaleditorContent}
                  onChange={handleModalEditorChange}
                  modules={modules} 
                  formats={formats} 
                  /> */}
                <QuillEditor
                  value={modaleditorContent}
                  onChange={handleModalEditorChange}
                />
              </EditorWrapper>
            </ModalBody>

            <ModalFooter>
              <SaveButton
                onClick={saveData}
                disabled={!modalselectedDate || !modaleditorContent || !selectedTab}
              >
                Submit
              </SaveButton>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>
      )}
    </OuterWarp>
  );
};
export default TabbedHighlights;
const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  ${media.small`
    gap: 8px;   
`}
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${media.small`
`}
`;

const CpProfileLink = styled.div`
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
    color: ${(props) => (props.islink === "true" ? "#0099FF" : "#000000")};
  }
  display: flex;
  white-space: nowrap;
  justify-content: flex-start;
  width: 25%;
  font-size: 16px;
  ${media.small`
font-size:10px;
`}
`;

const PlayerWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const OuterWarp = styled.div`
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
`;

const TabButton = styled.button`
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-bottom: ${({ active }) =>
    active ? "2px solid rgb(0, 153, 255)" : "none"};
  font-size: 14px;
  transition: background-color 0.3s;
  ${media.small`
      font-size: 10px;
        padding: 4.5px;

    `}
  &:hover {
    background-color: #f0f0f0;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const TabButtonsWrapper = styled.div`
  display: flex;
  gap: 0px;
  flex: 1;
  overflow-x: auto;
  ${media.small`
  flex-wrap: wrap;
  `}
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  position: relative;
  select {
    text-align: center;
    padding: 6px;
    font-size: 16px;
    background-color: white;
    border: 1px solid #b9bec1;
    border-radius: 5px;
  cursor: pointer;
    outline: none;
    transition: all 0.2s ease;
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
  &:hover {
      border-color: #a0aec0;
      background-color: #f7fafc;
  }
    
    &:focus {
      border-color: #0099FF;
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
      background-color: white;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #2d3748;
  }
  
    &::-ms-expand {
      display: none;
  }

    option {
      padding: 3px;
      font-size: 16px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  ${media.small`
    width: 22%;
  select {
      font-size: 11px;
      background-color: white;
      border: 1px solid #b9bec1;
      border-radius: 3px;
      &:hover,
      &:focus {
        background-color: white;
        box-shadow: none;
      }
      option {
      padding: 2px;
      font-size: 11px;
      
     }
    }
  `}
`;

const AddButton = styled.div`
  cursor: pointer;
  color: #0099ff;
  transition: transform 0.2s;
  padding: 6px;
  margin-left: -1px;
  ${media.small`
      padding: 2px;
  `}
`;

const ContentContainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
  font-size: 14px;
  position: relative;
  background-color: #fff;
  box-shadow: 2px 2px 4px 0px #00000040;
  max-height: 428px;
  ${media.small`
      font-size: 12px;
  `}
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  padding: 1px;
  margin-right: 5px;
  border: 0.5px solid #d1d1d1;
  border-radius: 5px;
  ${media.small`
  width: 40px;
  height: 40px;
  padding: 0.5px;
  margin-right: 3px;
  border: 0.25px solid #d1d1d1;
  border-radius: 3px;
//   display:none;
  `}
`;

const LoadingMessage = styled.div`
  min-height: 360px;
  padding: 15px 15px 15px;
`;

const EditorHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 10;
  padding: 5px;
  border-radius: 5px;
  ${media.small`
      top: 19px;
      right: 14px
    }
  `}
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  margin-left: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  &:hover {
    background-color: #0099ff;
    color: #fff;
    transform: scale(1.1);
  }
  position: relative;
`;

const DatePicker = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
`;



const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;

  ${media.small`
    width: 90%;
    margin: 20% auto;
  `}
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  h2 {
    font-size: 24px;
  }
`;

const ModalBody = styled.div`
  margin-top: 20px;
  max-height: 60vh;
  overflow-y: auto;
  flex-direction: column;
  gap: 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SaveButton = styled.button`
  background-color: #0099ff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
    width: 100%;
  &:hover {
    background-color: #007acc;
  }
  &:disabled {
    background-color: #cfcfcf;
    color: #808080;
    cursor: not-allowed;
    &:hover {
      background-color: #cfcfcf;
    }
  }
  ${media.small`
    padding: 8px 16px;
    font-size: 14px;
  `}
`;

const CloseButton = styled.div`
  cursor: pointer;
  ${media.small`
    font-size: 20px;
  `}
`;
const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  label {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

const ExcelButton = styled.button`
  background-color: white;
  border: 1px solid #b9bec1;
  border-radius: 5px;
  padding: 3px;
  font-size: 16px;
  width: 10%;
  outline: none !important;
  ${media.small`
    border-radius: 3px;
    width: 19%;
    border: 0.5px solid #b9bec1;
    font-size: 11px;
  `}
  ${media.medium`
      min-width:98px;
   `}
`;

const ModalText = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  white-space: nowrap;
  text-align:center;
`;
const ConfirmButton = styled.button`
  background-color: #0099ff;
  color: white;
  font-size:16px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;

const CancelButton = styled.button`
  background-color: red;
  color: white;
  padding: 10px 20px;
  font-size:16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;
const ConfirmModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 13px;
  border: 1px solid #888;
  border-radius: 10px;
  ${media.small`
    width: 90%;
    margin: 20% auto;
  `}
`;

const ConfirmModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
`;

const ConfirmModalFooter = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap:10px
`;

const DropdownContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  
  label {
    margin-bottom: -6px;
  }

  select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
`;