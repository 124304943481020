import clevertap from "clevertap-web-sdk";

export const onUserLoginCT = (name, email, gender, client_id) => {
  const integerClientId = Number(client_id);
  const subscription_type =
    integerClientId === 141
      ? "Privileged"
      : integerClientId === 1 || integerClientId === 53
      ? "Internal"
      : "Premium";
  console.log(
    `Clevertap - "User Login", ${name}, ${email}, ${gender}, ${subscription_type}`
  );
  clevertap.onUserLogin.push({
    Site: {
      Name: name,
      Identity: email,
      Email: email,
      Gender: gender,
      DOB: new Date(),
      "Subscription Type": subscription_type,
      "MSG-email": false,
      "MSG-push": false,
      "MSG-sms": false,
      "MSG-whatsapp": false,
    },
  });
  // let latitude = "";
  // let longitude = "";
  // if (navigator.geolocation) {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       latitude = position.coords.latitude;
  //       longitude = position.coords.longitude;
  //       console.log("Clevertap - ", latitude, longitude);
  //       clevertap.getLocation(latitude, longitude);
  //     },
  //     (error) => {
  //       console.error("Clevertap - Error getting location:", error);
  //     }
  //   );
  // } else {
  //   console.error("Clevertap - Geolocation is not supported by this browser.");
  // }
};

export const onQuickLinkClickCT = (report_name, report_id, subscribed) => {
  //sector only
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Quick Link", ${report_name}, ${String(
      report_id
    )}, ${subscribed}, ${dateNow}`
  );
  clevertap.event.push("Quick Link", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "Report Name": report_name,
    "Report Id": String(report_id),
    Subscribed: subscribed,
    Date: dateNow,
  });
};

export const onCompanyProfileClickCT = (
  //company only
  report_name,
  report_id,
  sp_report_name,
  sp_report_id
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Company Profile Open", ${report_name}, ${String(
      report_id
    )}, ${sp_report_name}, ${String(sp_report_id)}, ${dateNow}`
  );
  clevertap.event.push("Company Profile Open", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "Report Name": report_name,
    "Report Id": String(report_id),
    "SP Report Name": sp_report_name,
    "SP Report Id": String(sp_report_id),
    Date: String(new Date()),
  });
};

export const onDownloadExcelClickCT = (
  //sector only
  report_name,
  report_id,
  client_id,
  success
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Download Excel", ${report_name}, ${String(
      report_id
    )}, ${String(client_id)} ${success}, ${dateNow}`
  );
  clevertap.event.push("Download Excel", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "Report Name": report_name,
    "Report Id": String(report_id),
    "Client Id": String(client_id),
    success: success,
    Date: String(new Date()),
  });
};

export const onSectorReportOpenCT = (
  //sector only
  sp_report_name,
  sp_report_id,
  subscribed
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Sector Profile Open", ${sp_report_name}, ${String(
      sp_report_id
    )}, ${subscribed}, ${dateNow}`
  );
  clevertap.event.push("Sector Profile Open", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "Report Name": sp_report_name,
    "Report Id": String(sp_report_id),
    Subscribed: subscribed,
    Date: String(new Date()),
  });
};
export const onLandScapePageOpenCT = () => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Home Page Open", ${dateNow}`
  );
  clevertap.event.push("Home Page Open", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    Date: String(new Date()),
  });
};


export const onSectorLoadMoreClickCT = (
  //sector only
  sp_report_name,
  sp_report_id,
  subscribed
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "SP Load More", ${sp_report_name}, ${String(
      sp_report_id
    )}, ${subscribed}, ${dateNow}`
  );
  clevertap.event.push("SP Load More", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "Report Name": sp_report_name,
    "Report Id": String(sp_report_id),
    Subscribed: subscribed,
    Date: dateNow,
  });
};

export const onCPLoadMoreClickCT = (
  //company only (it can be combined with above)
  cp_report_name,
  cp_report_id,
  sp_report_name,
  sp_report_id,
  subscribed
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "CP Load More", ${cp_report_name}, ${String(
      cp_report_id
    )}, ${sp_report_name}, ${String(sp_report_id)}, ${subscribed}, ${dateNow}`
  );
  clevertap.event.push("CP Load More", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "Report Name": cp_report_name,
    "Report Id": String(cp_report_id),
    "SP Report Name": sp_report_name,
    "SP Report Id": String(sp_report_id),
    Subscribed: subscribed,
    Date: dateNow,
  });
};

export const onNewsletterClickCT = (
  newsletter_name, // for multiple news letter in a month date in the newsletter_name itself
  newsletter_month, // "Oct 2023", "Nov 2023"
  action, // sent, opened, clicked,
  newsletter_email,
  page // "Signin Page", "Signin Submit Page", "Otp Page", "Otp Submit Page", "Sector Profile Page"
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Newsletter", ${newsletter_name}, ${newsletter_month}, ${action}, ${newsletter_email}, ${page}, ${dateNow}`
  );
  clevertap.event.push("Newsletter", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    name: newsletter_name,
    month: newsletter_month,
    action: action,
    email: newsletter_email,
    page: page,
    date: dateNow,
  });
};

export const onReportSubscriptionRequestClickCT = (
  submitted, // True, False
  sp_report_name,
  sp_report_id,
  report_type, //Sector Profile, Company Profile,
  cp_report_name = "", // optional, required for CP
  cp_report_id = "" // optional, required for CP
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Report Subscription Request", ${cp_report_name}, ${String(
      cp_report_id
    )}, ${report_type}, ${sp_report_name}, ${String(
      sp_report_id
    )}, ${submitted}, ${dateNow}`
  );
  clevertap.event.push("Report Subscription Request", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "SP Report Name": sp_report_name,
    "SP Report Id": String(sp_report_id),
    "Report Type": report_type,
    "CP Report Name": cp_report_name,
    "CP Report Id": String(cp_report_id),
    Submitted: submitted,
    Date: dateNow,
  });
};

export const onCurrencySwitchClickCT = (
  currency, // USD, INR
  sp_report_name,
  sp_report_id,
  report_type, // Sector Profile, Company Profile,
  cp_report_name = "", // optional, required for CP
  cp_report_id = "" // optional, required for CP
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Currency Switch", ${currency}, ${cp_report_name}, ${String(
      cp_report_id
    )}, ${report_type}, ${sp_report_name}, ${String(sp_report_id)}, ${dateNow}`
  );
  clevertap.event.push("Currency Switch", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    Currency: currency,
    "SP Report Name": sp_report_name,
    "SP Report Id": String(sp_report_id),
    "Report Type": report_type,
    "CP Report Name": cp_report_name,
    "CP Report Id": String(cp_report_id),
    Date: dateNow,
  });
};

export const onYearSwitchClickCT = (
  year, // USD, INR
  sp_report_name,
  sp_report_id,
  report_type, // Sector Profile, Company Profile,
  cp_report_name = "", // optional, required for CP
  cp_report_id = "" // optional, required for CP
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Accounting Year Switch", ${year}, ${sp_report_name}, ${String(
      sp_report_id
    )}, ${report_type}, ${cp_report_name}, ${String(cp_report_id)}, ${dateNow}`
  );
  clevertap.event.push("Accounting Year Switch", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "Accounting Year": year,
    "SP Report Name": sp_report_name,
    "SP Report Id": String(sp_report_id),
    "Report Type": report_type,
    "CP Report Name": cp_report_name,
    "CP Report Id": String(cp_report_id),
    Date: dateNow,
  });
};

export const onReportPageVisitCT = (
  page_name,
  page_type,
  section_type,
  time_spent,
  sp_report_name,
  sp_report_id,
  report_type, // Sector Profile, Company Profile,
  cp_report_name = "", // optional, required for CP
  cp_report_id = "" // optional, required for CP
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Report Page Visit", ${page_name}, ${page_type}, ${section_type}, ${time_spent}, ${cp_report_name}, ${String(
      cp_report_id
    )}, ${report_type}, ${sp_report_name}, ${String(sp_report_id)}, ${dateNow}`
  );
  clevertap.event.push("Report Page Visit", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "Page Name": page_name,
    "Page Type": page_type,
    "Section Type": section_type,
    "Time Spent": time_spent,
    "SP Report Name": sp_report_name,
    "SP Report Id": String(sp_report_id),
    "Report Type": report_type,
    "CP Report Name": cp_report_name,
    "CP Report Id": String(cp_report_id),
    Date: dateNow,
  });
};

export const onReadContentClickCT = (
  content_title,
  content_link,
  content_type, //Artile, Report,
  client_id,
  sp_report_name,
  sp_report_id,
  report_type, //Sector Profile, Company Profile,
  cp_report_name = "", // optional, required for CP
  cp_report_id = "" // optional, required for CP
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Read Content", ${content_title}, ${content_link}, ${content_type}, ${String(
      client_id
    )}, ${cp_report_name}, ${String(
      cp_report_id
    )}, ${report_type}, ${sp_report_name}, ${String(sp_report_id)}, ${dateNow}`
  );

  clevertap.event.push("Read Content", {
    "Email": window.localStorage.getItem("email"),
    "User_Name": window.localStorage.getItem("user_name"),
    "Content Title": content_title,
    "Content Link": content_link,
    "Content Type": content_type,
    "Client Id": String(client_id),
    "SP Report Name": sp_report_name,
    "SP Report Id": String(sp_report_id),
    "Report Type": report_type,
    "CP Report Name": cp_report_name,
    "CP Report Id": String(cp_report_id),
    Date: String(new Date()),
  });
};


export const onLogoutLandingPageClickCT = (
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Landing Page Open on Logout", ${dateNow}`
  );
  clevertap.event.push("Logout Landing Page Open", {
    Date: String(new Date()),
  });
};

export const onSignInPageClickCT = (
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Sign In Page Open", ${dateNow}`
  );
  clevertap.event.push("Sign In Page Open", {
    Date: String(new Date()),
  });
};

export const onMSLoginClickCT = (
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Sign In with Microsoft clicked", ${dateNow}`
  );
  clevertap.event.push("Sign In with Microsoft clicked", {
    Date: String(new Date()),
  });
};

export const onGoogleLoginClickCT = (
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Sign In with Google clicked", ${dateNow}`
  );
  clevertap.event.push("Sign In with Google clicked", {
    Date: String(new Date()),
  });
};

export const onOTPLoginClickCT = (
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Sign In with OTP clicked", ${dateNow}`
  );
  clevertap.event.push("Sign In with OTP clicked", {
    Date: String(new Date()),
  });
};

export const onOTPLoginClickSuccessCT = (email
) => {
  const dateNow = String(new Date());
  console.log(
    `Clevertap - "Sign In with OTP success", ${dateNow}`
  );
  clevertap.event.push("Sign In with OTP success", {
    Email:email,
    Date: String(new Date()),
  });
};