import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { media } from "../../utils/media";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const currentYear = new Date().getFullYear();
  
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <FooterWrap>
      <DataSection>
      <FooterContent>
        <FooterLinks>
          <LinkItem>
            <NavLink
              isactive={
                activeLink === "/terms-of-use/"
              }
              onClick={() => {
                window.open("/terms-of-use/", "_blank")
              }}
            >
            Terms of Use
            </NavLink>
          </LinkItem>
          <LinkItem>
            <NavLink
              isactive={activeLink === "/privacy-policy"}
              onClick={() =>{ 
                window.open("/privacy-policy", "_blank")
              }}
            >
             Privacy Policy
            </NavLink>
          </LinkItem>
        </FooterLinks>
        <FooterText>&copy; {currentYear} Redseer</FooterText>
      </FooterContent>
      </DataSection>
    </FooterWrap>
  );
};

export default Footer;

const FooterWrap = styled.div`
  background-color: rgb(255, 255, 255);
  padding-top:8px;
  height:7vh;
  border-top: 1px solid #e0e0e0;
  position: relative;
  width: 100%;
  margin-top: 0;
  z-index: 1;
  box-sizing: border-box;
  clear: both;
  padding-bottom: 16px;
`;

const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const LinkItem = styled.div`
  margin-right: 30px;
  ${media.small`
    margin-right: 15px;
  `}
`;

const NavLink = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  white-space: nowrap;
  color: ${(props) => (props.isactive ? "#0099FF" : "#000000")};
  cursor: pointer;
  &:hover {
    color: #0099ff;
  }

  ${media.small`
    font-size: 12px;
    margin: 0px 2.5px;
  `}
`;

const FooterText = styled.div`
  font-size: 14px;
  color: #333;
  ${media.small`
    font-size: 12px;
  `}
`;
