import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { media } from "../../utils/media";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClientsIdsData,
  fetchNewExcelsData,
  selectnewClientIdsData,
  selectnewexcelData,
} from "../../utils/redux/slices";
import dayjs from "dayjs";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import ReactQuill from "react-quill";
import toast from "react-hot-toast";
import { Upload, Row, Col, Card } from "antd";
import { InboxOutlined,DeleteOutlined,FileOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { TailSpin } from "react-loader-spinner";
import { LuFileSpreadsheet } from "react-icons/lu";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const ClientUploads = () => {
  const [formData, setFormData] = useState({
    reportName: "",
    reportSelection: "",
    startDate: "",
    endDate: "",
    file: null,
    fileName: "",
    client_id: "",
    fileUrl: "",
    type:"weekly",
    last_updated:"",
  });

  const [monthlyformData, setmonthlyFormData] = useState({
    reportName: "",
    reportSelection: "",
    startDate: "",
    endDate: "",
    file: null,
    client_id: "",
    fileUrl: "",
    last_updated:"",
  });
  const [emails, setEmails] = useState([]);
  const [ccemails, setccEmails] = useState([]);
  const [Bccemails, setbccEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [activeTab, setActiveTab] = useState("monthly");
  const dispatch = useDispatch();
  const newexcelaccessData = useSelector(selectnewexcelData);
  const clientIdsData = useSelector(selectnewClientIdsData);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [monthlyfileList, setmonthlyFileList] = useState([]);
  const [weeklyfileList, setweeklyFileList] = useState([]);
  const [excelLoaderState, setExcelLoaderState] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const showEndPicker = windowWidth <= 480;
  const getResponsiveStyle = () => {
    const width = window.innerWidth;
    if (width <= 480) {
      return { fontSize: '12px', padding: '12px' };
    } else if (width <= 768) {
      return { fontSize: '12px', padding: '14px' };
    } else {
      return { fontSize: '14px', padding: '16px' };
    }
  };
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = ({ fileList }) => {
    const latestFile = fileList.slice(-1);
    setweeklyFileList(latestFile);

    if (latestFile[0]) {
      setFormData((prevState) => ({
        ...prevState,
        file: latestFile[0].originFileObj,
        fileName: latestFile[0].name,
      }));
      toast.success('Report successfully fetched!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          fontSize: '14px',
          ...getResponsiveStyle(),
        }
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        fileName: "",
      }));
    }
  };

  const handlemonthlyFileChange = ({ fileList }) => {
    const latestFile = fileList.slice(-1);
    setmonthlyFileList(latestFile);

    if (latestFile[0]) {
      setmonthlyFormData((prevState) => ({
        ...prevState,
        file: latestFile[0].originFileObj,
      }));
      setFileName(latestFile[0].name);
      toast.success('Report successfully fetched!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: null,
        style: {
          fontSize: '14px',
          ...getResponsiveStyle(),
        }
      });
    } else {
      setFileName("");
    }
  };

  const handleEmailChange = (newEmails) => {
    const lowerCaseEmails = newEmails.map((email) => email.toLowerCase());
    const uniqueNewEmails = [...new Set(lowerCaseEmails)];
    if (newEmails.length < emails.length) {
      setEmails(newEmails);
      return;
    }
    const validNewEmails = uniqueNewEmails.filter(
      (email) => !emails.includes(email)
    );
    if (validNewEmails.length === 0) {
      toast.error("Email already exists or is a duplicate!", {
        duration: 4000,
        position: "top-right",
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
        removeDelay: 1000,
      });
      return;
    }
    setEmails([...emails, ...validNewEmails]);
  };
  const handleCCEmailChange = (newEmails) => {
    const lowerCaseEmails = newEmails.map((email) => email.toLowerCase());
    const uniqueNewEmails = [...new Set(lowerCaseEmails)];
    if (newEmails.length < ccemails.length) {
      setccEmails(newEmails);
      return;
    }
    const validNewEmails = uniqueNewEmails.filter(
      (email) => !ccemails.includes(email)
    );
    if (validNewEmails.length === 0) {
      toast.error("Email already exists or is a duplicate!", {
        duration: 4000,
        position: "top-right",
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
        removeDelay: 1000,
      });
      return;
    }
    setccEmails([...ccemails, ...validNewEmails]);
  };
  const handlebCCEmails = (newEmails) => {
    const lowerCaseEmails = newEmails.map((email) => email.toLowerCase());
    const uniqueNewEmails = [...new Set(lowerCaseEmails)];
    if (newEmails.length < Bccemails.length) {
      setbccEmails(newEmails);
      return;
    }
    const validNewEmails = uniqueNewEmails.filter(
      (email) => !Bccemails.includes(email)
    );
    if (validNewEmails.length === 0) {
      toast.error("Email already exists or is a duplicate!", {
        duration: 4000,
        position: "top-right",
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
        removeDelay: 1000,
      });
      return;
    }
    setbccEmails([...Bccemails, ...validNewEmails]);
  };
  const handleMonthlyChange = (e) => {
    const { name, value } = e.target;
    setmonthlyFormData({ ...monthlyformData, [name]: value });
  };

  useEffect(() => {
    dispatch(fetchNewExcelsData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchClientsIdsData());
  }, [dispatch]);

  useEffect(() => {
    const fetchFile = async () => {
      let apiEndpoint = "";
      let reportSelection = "";
      let clientId = "";
      let url = "";
      let type = "";
      const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
      const industry_id = IndustryDict[monthlyformData.reportSelection]?.industry_id;
      const is_athena = process.env.REACT_APP_API_ENDPOINT.includes("athena");
  
      if (formData.type === "quarterly" || formData.type === "one_off" || formData.type === "workshop") {
        setFormData((prev) => ({ ...prev, file: null, fileName: "", fileUrl: "" ,last_updated:"",}));
        return;
      }
      if (activeTab === "weekly" && formData.client_id && formData.reportSelection && (formData.type === "weekly" || formData.type === "monthly")) {
        apiEndpoint = "/show-existing-weekly/";
        reportSelection = formData.reportSelection;
        clientId = formData.client_id;
        type =formData.type === "weekly" ? "weekly" : "monthly";
      } else if (activeTab === "monthly" && monthlyformData.reportSelection && monthlyformData.client_id) {
        apiEndpoint = "/show-existing-monthly/";
        reportSelection = monthlyformData.reportSelection;
        clientId = monthlyformData.client_id;
        url = `${process.env.REACT_APP_BM_ENDPOINT}/newexcel/?client_id=${monthlyformData.client_id}&report_id=${monthlyformData.reportSelection}&is_athena=false&industry_id=${industry_id}&email_id=${window.localStorage.getItem('email')}`;
      } else {
        return;
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BM_ENDPOINT}${apiEndpoint}`,
          { report_id: parseInt(reportSelection), client_id: clientId,type:type },
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (response.status === 200 && response.data.file && response.data.file === "yes") {
          const excelResponse = await fetch(url, {
            method: "GET",
            cache: "no-store",
            headers: {
              "Content-Type": "application/json",
            },
          });
          const res = await excelResponse.json();
          if (res["excel_link"]) {
            const fileUrl = res["excel_link"];
  
            const fileResponse = await axios.get(fileUrl, { responseType: "blob" });
            const extractedFile = new File([fileResponse.data], "monthly_report.xlsx", {
              type: fileResponse.headers["content-type"],
            });
            setmonthlyFormData((prev) => ({
              ...prev,
              file: extractedFile,
              fileUrl: fileUrl,
              last_updated: response.data.last_updated 
              ? new Date(response.data.last_updated).toLocaleString('en-IN', { 
                  day: '2-digit', 
                  month: 'short', 
                  year: 'numeric', 
                  hour: '2-digit', 
                  minute: '2-digit', 
                  second: '2-digit', 
                  hour12: true, 
                  timeZone: 'Asia/Kolkata' 
                }).replace(',', '') 
              : "Up to Date"
            }));
            
            toast.success('Report successfully fetched!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                fontSize: '14px',
                ...getResponsiveStyle(),
              }
            });
          } else {
            setmonthlyFormData((prev) => ({ ...prev, file: null, fileUrl: "" ,last_updated:""}));
          }
        } else {
          if (activeTab === "weekly") {
            setFormData((prev) => ({ ...prev, file: null, fileName: "", fileUrl: "" ,last_updated:"",}));
          } else {
            setmonthlyFormData((prev) => ({ ...prev, file: null, fileUrl: "",last_updated:"" }));
          }
        }
        if (response.status === 200 && response.data.file !== "yes") {
          const fileUrl =`${process.env.REACT_APP_BM_ENDPOINT}${response.data.file}`;
          const fileResponse = await axios.get(fileUrl, { responseType: "blob" });
          const extractedFile = new File([fileResponse.data], response.data.file.split("/").pop(), {
            type: fileResponse.headers["content-type"],
          });
  
          if (activeTab === "weekly") {
            setFormData((prev) => ({
              ...prev,
              file: extractedFile,
              fileName: extractedFile.name,
              fileUrl: fileUrl,
              last_updated: response.data.created?new Date(response.data.created).toLocaleString('en-IN', { 
                day: '2-digit', 
                month: 'short', 
                year: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit', 
                second: '2-digit', 
                hour12: true, 
                timeZone: 'Asia/Kolkata' 
              }).replace(',', '') :"Up to Date"
            }));
            toast.success('Report successfully fetched!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                fontSize: '14px',
                ...getResponsiveStyle(),
              }
            });
          } 
        }
      } catch (error) {
        console.error("Error fetching file:", error);
        if (activeTab === "weekly") {
          setFormData((prev) => ({ ...prev, file: null, fileName: "", fileUrl: "" ,last_updated:"",}));
        } else {
          setmonthlyFormData((prev) => ({ ...prev, file: null, fileUrl: "",last_updated:"" }));
        }
      }
    };
  
    fetchFile();
  }, [
    activeTab,
    formData.client_id,
    formData.reportSelection,
    monthlyformData.client_id,
    monthlyformData.reportSelection,
    formData.type 
  ]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = new FormData();
    payload.append("client_id", formData.client_id);
    payload.append("report_id", formData.reportSelection);
    payload.append("start_date", formData.startDate);
    payload.append("end_date", formData.endDate);
    payload.append("file_name", formData.fileName);
    payload.append("type", formData.type);
    if (formData.file) {
      payload.append("file", formData.file);
    }

    try {
    const response = await axios.post(
        `${process.env.REACT_APP_BM_ENDPOINT}/client-files/`,
        payload,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if(response){
        toast.success('Report successfully saved!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            fontSize: '14px',
            ...getResponsiveStyle(),
          }
        });
      }
      setFormData((prev) => ({
        ...prev,
        client_id: "",
        reportName: "",
        reportSelection: "",
        startDate: "",
        endDate: "",
        file: null,
        fileName: "",
        fileUrl: "",
        type:"weekly",
        last_updated:"",
      }));
      setweeklyFileList([])
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleMonthlySubmit = async (e) => {
    e.preventDefault();

    const payload = new FormData();
    payload.append("client_id", monthlyformData.client_id);
    payload.append("report_id", monthlyformData.reportSelection);
    payload.append("start_date", monthlyformData.startDate);
    payload.append("end_date", monthlyformData.endDate);
    payload.append("report_access", monthlyformData.reportSelection);
    if (monthlyformData.file) {
     payload.append("file",monthlyformData.file)
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BM_ENDPOINT}/upload-client-file/`,
        payload,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if(response){
        toast.success('Report successfully saved!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            fontSize: '14px',
            ...getResponsiveStyle(),
          }
        });
      }
      setmonthlyFormData({
        client_id: "",
        reportName: "",
        reportSelection: "",
        startDate: "",
        endDate: "",
        file: null,
        fileUrl: "",
        last_updated:""
      });
      setmonthlyFileList([])
      setFileName('')
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSendMail = async () => {
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("message", message);
    formData.append("recipient_list", JSON.stringify(emails));
    fileList.forEach((file) => {
      const actualFile = file.originFileObj || file; 
      formData.append("attachments", actualFile);
    });
    formData.append("cc_list", JSON.stringify(ccemails));
    formData.append("bcc_list", JSON.stringify(Bccemails));
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BM_ENDPOINT}/send-custom-mail/`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setEmails([]);
      setSubject("");
      setMessage("");
      setFileList([])
      setccEmails([])
      setbccEmails([])
      if (response) {
        toast.success(response.data.message, {
          duration: 3000,
          position: "top-right",
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        });
      }
    } catch (error) {
      console.error("Error sending mail:", error);
    }
  };

  const handleFileListChange = ({ fileList }) => {
    setFileList(fileList);
    toast.success('Reports successfully fetched!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        fontSize: '14px',
        ...getResponsiveStyle(),
      }
    });
  };

  const handleRemoveFile = (file) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  const downloadFile = async (fileUrl) => {
    try {
        setExcelLoaderState(true);
        const response = await fetch(fileUrl);
        if (!response.ok) throw new Error("Network response was not ok");

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "download_link"; 
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        await new Promise((resolve) => setTimeout(resolve, 1000));

    } catch (error) {
        console.error("Download failed:", error);
    } finally {
        setExcelLoaderState(false);
    }
};

  const renderMonthlyForm = () => {
    return (
      <Card title="Monthly Sector Reports"  bordered={true}
      style={{ width: "100%", margin: "auto", padding: "16px",borderRadius:"0px" }}>
      <GridWraper>
        <Label>Report Selection:</Label>
        <SelectDropdown
          name="reportSelection"
          onChange={handleMonthlyChange}
          value={monthlyformData.reportSelection}
        >
          <option value="">Select</option>
          {newexcelaccessData.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </SelectDropdown>
        <Label>Client ID:</Label>
        <Select
          name="client_id"
          value={
            clientIdsData?.find(
              (option) => option.id === monthlyformData.client_id
            )
              ? {
                  value: monthlyformData.client_id,
                  label: `${monthlyformData.client_id} - ${
                    clientIdsData.find(
                      (option) => option.id === monthlyformData.client_id
                    )?.name
                  }`,
                }
              : null
          }
          onChange={(selectedOption) => {
            setmonthlyFormData((prevState) => ({
              ...prevState,
              client_id: selectedOption ? selectedOption.value : "",
            }));
          }}
          options={clientIdsData?.map((item) => ({
            value: item.id,
            label: `${item.id} - ${item.name}`,
          }))}
          placeholder="Select a Client"
          isClearable
          isSearchable
          styles={{
            control: (base, state) => ({
              ...base,
              fontSize: "16px",
              "@media (max-width: 600px)": {
                fontSize: "12px",
              },
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
            option: (base, state) => ({
              ...base,
              fontSize: "16px",
              "@media (max-width: 600px)": {
                fontSize: "12px", 
              },
            }),
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
        />
        <Label>Date Range:</Label>
        <RangePicker
            format="MM/DD/YYYY"
            value={[
              monthlyformData.startDate ? dayjs(monthlyformData.startDate) : null,
              monthlyformData.endDate ? dayjs(monthlyformData.endDate) : null,
            ]}
            onChange={(dates, dateStrings) => {
              const [start, end] = dateStrings;
              setmonthlyFormData((prev) => ({
                ...prev,
                startDate: dayjs(start).format("YYYY-MM-DD"),
                endDate: dayjs(end).format("YYYY-MM-DD"),
              }));
            }}
            className={
              showEndPicker
                ? "custom-range-picker-dropdown show-end"
                : "custom-range-picker-dropdown"
            }
            style={{ width: "100%", height: "50px", borderRadius: "8px" }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            key={windowWidth}
          />
        <Label>Upload File: <p style={{color:"red",marginLeft:"3px"}}>  If No Download file exists or Format is not correct than upload a new file</p></Label>
        {monthlyformData.fileUrl && typeof monthlyformData.fileUrl === "string" && (
          <div style={{
            display:"flex",alignItems:"center",gap:"20px"
          }}>
            Existing file:{" "}
            <ExcelButtonWrapper>
            <ExcelButton onClick={() => downloadFile(monthlyformData.fileUrl)}>
              {excelLoaderState ? (
                <LoaderWrapper>
                  <TailSpin height="20" width="20" color="#0066CC" ariaLabel="loading" radius="1" visible={true} />
                  <LoadingText>Preparing...</LoadingText>
                </LoaderWrapper>
              ) : (
                <>
                  <LuFileSpreadsheet size={18} />
                  <span>Download File</span>
                </>
              )}
            </ExcelButton>
            <div style={{
            display:"flex",alignItems:"center",gap:"20px",marginLeft:"20px"
          }}>Last Updated: {monthlyformData.last_updated}</div>
          </ExcelButtonWrapper>
            {/* <ExcelButton
              href={monthlyformData.fileUrl}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              Download file
            </ExcelButton> */}
          </div>
        )}
      <Dragger
        name="file"
        multiple={false}
        fileList={monthlyfileList}
        onChange={handlemonthlyFileChange}
        beforeUpload={() => false} 
        maxCount={1}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file here to upload</p>
        <p className="ant-upload-hint">Only one file allowed.</p>
      </Dragger>
      {fileName && (
        <p style={{ marginTop: 10, color: "green" }}>
          Uploaded File: <strong>{fileName}</strong>
        </p>
      )}
        <Button type="submit" onClick={handleMonthlySubmit}>
          Submit
        </Button>
      </GridWraper>
      </Card>
    );
  };

  const renderWeeklyForm = () => {
    return (
      <Card
        title="Others Report"
        bordered={true}
        style={{
          width: "100%",
          margin: "auto",
          padding: "16px",
          borderRadius: "0px",
        }}
      >
        <GridWraper>
          <Label>Report Selection:</Label>
          <SelectDropdown
            name="reportSelection"
            onChange={handleChange}
            value={formData.reportSelection}
          >
            <option value="">Select</option>
            {newexcelaccessData.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </SelectDropdown>
          <Label>Output Type:</Label>
          <SelectDropdown
            name="type"
            onChange={handleChange}
            value={formData.type}
          >
            {[
              ["weekly", "Weekly Report"],
              ["monthly", "Monthly Report"],
              ["quarterly", "Quarterly Report"],
              ["one_off", "One off Report"],
              ["workshop", "Workshop Deck"],
              // ["others", "Others"],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </SelectDropdown>
          <Label>Client ID:</Label>
          <Select
            name="client_id"
            value={
              clientIdsData?.find((option) => option.id === formData.client_id)
                ? {
                    value: formData.client_id,
                    label: `${formData.client_id} - ${
                      clientIdsData.find(
                        (option) => option.id === formData.client_id
                      )?.name
                    }`,
                  }
                : null
            }
            onChange={(selectedOption) => {
              setFormData((prevState) => ({
                ...prevState,
                client_id: selectedOption ? selectedOption.value : "",
              }));
            }}
            options={clientIdsData?.map((item) => ({
              value: item.id,
              label: `${item.id} - ${item.name}`,
            }))}
            placeholder="Select a Client"
            isClearable
            isSearchable
            styles={{
              control: (base, state) => ({
                ...base,
                fontSize: "16px",
                "@media (max-width: 600px)": {
                  fontSize: "12px",
                },
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (base) => ({ ...base, zIndex: 9999 }),
              option: (base, state) => ({
                ...base,
                fontSize: "16px",
                "@media (max-width: 600px)": {
                  fontSize: "12px",
                },
              }),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }}
          />

          <Label>File Name</Label>
          <Input
            name="file"
            type="text"
            placeholder="Enter your desire name"
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                fileName: e.target.value,
              }))
            }
            value={formData.fileName || ""}
          />
          <Label>Date Range:</Label>
          <RangePicker
            format="MM/DD/YYYY"
            value={[
              formData.startDate ? dayjs(formData.startDate) : null,
              formData.endDate ? dayjs(formData.endDate) : null,
            ]}
            onChange={(dates, dateStrings) => {
              const [start, end] = dateStrings;
              setFormData((prev) => ({
                ...prev,
                startDate: dayjs(start).format("YYYY-MM-DD"),
                endDate: dayjs(end).format("YYYY-MM-DD"),
              }));
            }}
            className={
              showEndPicker
                ? "custom-range-picker-dropdown show-end"
                : "custom-range-picker-dropdown"
            }
            style={{ width: "100%", height: "50px", borderRadius: "8px" }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            key={windowWidth}
          />
          <Label>
            Upload File:{" "}
            <p style={{ color: "red", marginLeft: "3px" }}>
              {" "}
              If No Download file exists or Format is not correct than upload a
              new file
            </p>
          </Label>
          
          {formData.fileUrl && typeof formData.fileUrl === "string" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              Existing file:{" "}
              <ExcelButtonWrapper>
                <ExcelButton onClick={() => downloadFile(formData.fileUrl)}>
                  {excelLoaderState ? (
                    <LoaderWrapper>
                      <TailSpin
                        height="20"
                        width="20"
                        color="#0066CC"
                        ariaLabel="loading"
                        radius="1"
                        visible={true}
                      />
                      <LoadingText>Preparing...</LoadingText>
                    </LoaderWrapper>
                  ) : (
                    <>
                      <LuFileSpreadsheet size={18} />
                      <span>Download File</span>
                    </>
                  )}
                </ExcelButton>
                <div style={{
            display:"flex",alignItems:"center",gap:"20px",marginLeft:"20px"
          }}>Last Updated: {formData.last_updated}</div>
              </ExcelButtonWrapper>
              {/* <a
              href={formData.fileUrl}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              {formData.fileUrl}
            </a> */}
            </div>
          )}
          <Dragger
            name="file"
            multiple={false}
            fileList={weeklyfileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file here to upload</p>
            <p className="ant-upload-hint">Only one file allowed.</p>
          </Dragger>
          <Button type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </GridWraper>
      </Card>
    );
  };

  const renderSendMailForm = () => {
    return (
      <Card
        title="Send Email"
        style={{
          width: "100%",
          margin: "auto",
          padding: "16px",
          borderRadius: "0px",
        }}
      >
        <GridWraper>
          <Label>Recipient Email:</Label>
          <StyledTagsInput
            value={emails}
            onChange={handleEmailChange}
            inputProps={{
              type: "email",
              placeholder: "Enter email and press Enter",
              style: {
                border: "none",
                boxShadow: "none",
                outline: "none",
              },
            }}
          />
           <Label>Cc:</Label>
          <StyledTagsInput
            value={ccemails}
            onChange={handleCCEmailChange}
            inputProps={{
              type: "email",
              placeholder: "Enter cc email and press Enter",
              style: {
                border: "none",
                boxShadow: "none",
                outline: "none",
              },
            }}
          />
          <Label>BCC:</Label>
          <StyledTagsInput
            value={Bccemails}
            onChange={handlebCCEmails}
            inputProps={{
              type: "email",
              placeholder: "Enter bcc and press Enter",
              style: {
                border: "none",
                boxShadow: "none",
                outline: "none",
              },
            }}
          />
          <Label>Subject:</Label>
          <Input
            type="text"
            placeholder="Enter subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <Label>Message:</Label>
          <ReactQuill value={message} onChange={setMessage} />
          <Label>Upload Attachments:</Label>
          <Upload.Dragger
            multiple
            beforeUpload={() => false}
            fileList={fileList}
            onChange={handleFileListChange}
            onRemove={handleRemoveFile}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag files here to upload
            </p>
          </Upload.Dragger>
          {fileList?.length > 0 && (
            <Row gutter={[10, 10]}>
              {fileList?.map((file) => (
                <Col xs={12} sm={6} md={4} lg={3} key={file.uid}>
                  <Card
                    hoverable
                    cover={
                      <FileOutlined
                        style={{
                          fontSize: "20px",
                          margin: "10px auto",
                          display: "block",
                        }}
                      />
                    }
                    actions={[
                      <DeleteOutlined
                        key="delete"
                        onClick={() => handleRemoveFile(file)}
                        style={{ color: "red", fontSize: "16px" }}
                      />,
                    ]}
                  >
                    <Card.Meta
                      title={file.name}
                      style={{
                        textAlign: "center",
                        fontSize: "10px",
                        marginTop: "-5px",
                      }}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          )}
          <Button type="submit" onClick={handleSendMail}>
            Send Mail
          </Button>
        </GridWraper>
      </Card>
    );
  };

  return (
    <PageWrapper>
      <Header />
      <TabButtonsWrapper>
        <TabButton
          active={activeTab === "monthly"}
          onClick={() => setActiveTab("monthly")}
        >
          Monthly Sector Reports
        </TabButton>
        <TabButton
          active={activeTab === "weekly"}
          onClick={() => setActiveTab("weekly")}
        >
          Others Report
        </TabButton>
        <TabButton
          active={activeTab === "sendmail"}
          onClick={() => setActiveTab("sendmail")}
        >
          Send Mail
        </TabButton>
      </TabButtonsWrapper>
      <DataSection>
        {activeTab === "weekly" && renderWeeklyForm()}
        {activeTab === "monthly" && renderMonthlyForm()}
        {activeTab === "sendmail" && renderSendMailForm()}
      </DataSection>
      <Footer />
    </PageWrapper>
  );
};

export default ClientUploads;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eef9ff;
  min-height: 90vh;
`;

const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
  margin-bottom: 10px;
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
  margin-bottom: 10px;
  min-height: 84vh;
  gap: 20px;
  border: 1px solid #ccc;
  background: #fff;
  ${media.small`
     margin-bottom: 10px;
    `}
`;

const Label = styled.label`
  display: flex;
  font-size: 16px;
  margin-bottom: -20px;
  font-weight: 500;
  ${media.small`
    font-size: 12px;
    `}
`;

const SelectDropdown = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size:16px;
  background-color:#fff;
  ${media.small`
    font-size: 12px;
    `}
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size:16px;
  ${media.small`
    font-size: 12px;
    `}
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

// const FileUpload = styled.input`
//   width: 100%;
//   padding: 5px;
// `;

const TabButtonsWrapper = styled.div`
  display: flex;
  gap: 0px;
  flex: 1;
  overflow-x: auto;
  padding: 10px 3.5vw 0px;
  ${media.small`
    flex-wrap: wrap;
  `}
  &:focus {
    outline: none;
  }
`;

const TabButton = styled.button`
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-bottom: ${({ active }) =>
    active ? "2px solid rgb(0, 153, 255)" : "none"};
  font-size: 14px;
  transition: background-color 0.3s;
  ${media.small`
      font-size: 10px;
        padding: 4.5px;

    `}
  &:hover {
    background-color: #f0f0f0;
  }
`;

const StyledTagsInput = styled(TagsInput)`
  background-color: #fff;
  border: 1px solid #ccc;
  padding-left: 5px;
  border-radius: 4px;
  font-size: 16px;
  .react-tagsinput-tag {
    background-color: #0099ff;
    color: #ffffff;
    font-size: 14px;
    border: 1px solid #0099ff;
  }
  .react-tagsinput--focused {
    border-color: #ccc;
  }
  .react-tagsinput-input {
    font-size: 14px;
    color: #000000;
    width: 100%;
    padding: 12px 12px;
    margin-bottom: 3px;
  }
  & input[type="text"] {
    padding: 6px 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    color: #000000;
  }
`;
const ExcelButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ExcelButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  ${media.small`
    font-size: 12px;
    `}
  &:hover {
    background-color: #e2e8f0;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const LoadingText = styled.span`
  font-size: 14px;
  color: #64748b;
`;