import React from "react";
import styled, { useTheme } from "styled-components";
import { media } from "../../../../utils/media.js";
import moment from "moment";
import TriangleIcon2 from "../../../svg/TriangleIcon2.js";

const KpiCard = ({
  name = "",
  unitText = "",
  value,
  increment,
  date = moment(moment().startOf("month").subtract(2, "month")).format(
    "YYYY-MM-DD"
  ),
  formatCode = "hi",
  isBlurred,
  date_end
}) => {

  const decimalCount = value ? (Number(value) > 1000 ? 0 : 2) : 0;
  return (
    <Wrapper>
      <Title>{name}</Title>
      <SubTitle>{unitText}</SubTitle>
      <ValueWrap>
        <Value color={value == null ? "#808080" : "#000000"} isBlurred={isBlurred}>
          {value
            ? Number(value).toLocaleString(formatCode, {
                maximumFractionDigits: decimalCount,
              })
            : "-"}
        </Value>
        {increment && (
          <IconWrap
            color={increment > 0 ? "#21AF4A" : "#E93735"}
            rotate={increment > 0 ? 180 : 0}
          />
        )}
      </ValueWrap>
      <IncrementWrap>
        <Increment
          color={
            increment ? (increment > 0 ? "#21AF4A" : "#E93735") : "#808080"
          }
          isBlurred={isBlurred}
        >
          {increment
            ? `${increment > 0 ? "+" : ""}${Number(increment).toLocaleString(
                formatCode,
                {
                  maximumFractionDigits: 2,
                }
              )}%`
            : ""}
        </Increment>
        <IncrementText>{increment ? "yoy" : ""}</IncrementText>
      </IncrementWrap>
      <Detail>
        for the month of
        <MonthBox>{date_end?.end_date?moment(date_end?.end_date, "YYYY-MM-DD").format("MMM'YY"):moment(date, "YYYY-MM-DD").format("MMM'YY")}</MonthBox>
      </Detail>
    </Wrapper>
  );
};

export default KpiCard;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.medium`
    font-size: 14px;
  `}
  ${media.small`
    font-size: 11px;
  `}
`;

const SubTitle = styled.div`
  font-size: 15px;
  min-height: 15px;
  line-height: 15px;
  ${media.medium`
    font-size: 13px;
    line-height: 13px;
    min-height: 13px;
  `}
  ${media.small`
    font-size: 10.5px;
    line-height: 10.5px;
    min-height: 10.5px;
  `}
`;

const ValueWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Value = styled.div`
  white-space: nowrap;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  color: ${(props) => props.color};
  filter: ${(props) => (props.isBlurred === true ? "blur(10px)" : "none")};
  ${media.medium`
		font-size: 30px;
  	line-height: 30px;
	`}
  ${media.small`
		font-size: 20px;
  	line-height: 20px;
	`}
`;

const IconWrap = styled(TriangleIcon2)`
  width: 40px;
  height: 40px;
  margin-right: 15px;
  ${media.medium`
		margin-right: 10px;
		width: 35px;
		height: 35px;
	`}
  ${media.small`
		margin-right: 5px;
		width: 20px;
		height: 20px;
	`}
`;

const IncrementWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 28px;
  ${media.medium`
    min-height: 22px;
  `}
  ${media.small`
    min-height: 20px;
  `}
`;

const Increment = styled.div`
  font-size: 19px;
  font-weight: 400;
  color: ${(props) => props.color};
  filter: ${(props) => (props.isBlurred === true ? "blur(10px)" : "none")};
  ${media.medium`
    font-size: 15px;
  `}
  ${media.small`
    font-size: 13px;
  `}
`;

const IncrementText = styled.span`
  font-size: 12px;
  font-style: italic;
  color: #808080;
  padding-left: 5px;
  font-weight: 300;
  ${media.medium`
    font-size: 10px;
    padding-left: 4px;
  `}
  ${media.small`
    font-size: 9px;
    padding-left: 3px;
  `}
`;

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-style: italic;
  color: #808080;
  ${media.medium`
    font-size: 10px;
  `}
  ${media.small`
    font-size: 9px;
  `}
`;

const MonthBox = styled.span`
  font-size: 12px;
  font-style: italic;
  color: #454545;
  padding-left: 5px;
  ${media.medium`
    font-size: 10px;
    padding-left: 4px;
  `}
  ${media.small`
    font-size: 9px;
    padding-left: 3px;
  `}
`;
