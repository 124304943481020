import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const QuillEditor = React.memo(({ value, onChange }) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);
  useEffect(()=>{
    if (!quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': ['arial', 'serif', 'monospace'] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
          ],
        },
      });

      quillRef.current.on('text-change', () => {
        const content = quillRef.current.root.innerHTML;
        onChange(content);
      });
    }

  },[])
  useEffect(() => {
    // Initialize Quill editor only once
    
    // Set initial content
    if (quillRef.current) {
      quillRef.current.root.innerHTML = value;
    }

    return () => {
      // Cleanup the Quill instance
      quillRef.current = null;
    };
  }, []); // Run only once on mount

  useEffect(() => {
    // Update the content when the value prop changes
    if (quillRef.current && quillRef.current.root.innerHTML !== value) {
      quillRef.current.root.innerHTML = value;
    }
  }, [value]); // Update content when value changes

  return <div ref={editorRef} style={{ height: '300px' }} />; // You can adjust the height as needed
});

export default QuillEditor;