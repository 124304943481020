// userService.js
import axios from 'axios';
import prod_api from './api_prod';


export const getDownloadsClientExcel=(client_id)=>{
  return prod_api.get(`/client-excels/?client_id=${client_id}`);
}

export const getDownloadsClientFiles=(client_id)=>{
  return prod_api.get(`/client-files/?client_id=${client_id}`);
}

export const getClientIds=()=>{
  return prod_api.get(`client-list/`);
}