import { useState } from "react";
import styled from "styled-components";

const FeedbackTextWithToggle = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  const truncatedText = text.length > 50 ? `${text.substring(0, 50)}...` : text;

  return (
    <FeedbackTextContainer>
      {expanded ? text : truncatedText}
      {text.length > 50 && (
        <ToggleButton onClick={() => setExpanded(!expanded)}>
          {expanded ? 'View Less' : 'View More'}
        </ToggleButton>
      )}
    </FeedbackTextContainer>
  );
};
export default FeedbackTextWithToggle;
const FeedbackTextContainer = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

const ToggleButton = styled.div`
  background: none;
  border: none;
  color:rgb(96, 107, 118);
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  padding: 0;
  text-decoration: none;
  &:hover {
    color: #40a9ff;
  }
`;