import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { openDB } from 'idb';
import { saveToDb, getFromDb } from './idb'; 
import { getClientIds, getDownloadsClientExcel, getDownloadsClientFiles } from '../../centralizedAPICalls/prod_api';
import axios from 'axios';
const counterSlice = createSlice({
  name: 'counter',
  initialState: 0,
  reducers: {
    increment: state => state + 1,
    decrement: state => state - 1
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (state, action) => action.payload,
    logoutUser: () => null
  }
});

export const newreportaccessSlice = createSlice({
  name:'newreportaccessdata',
  initialState:{
    value:[]
  },
  reducers:{
    setNewReportAccessData:(state,action)=>{
      state.value = action.payload
    }
}})

export const fetchNewExcelsData = createAsyncThunk(
  "newexcelData/fetchNewExcelsData",
  async (_, { rejectWithValue }) => {
    try {
      let client_id = window.localStorage.getItem("clientID");
      const result = await getDownloadsClientExcel(client_id);

      if (!result || result.length === 0) {
        return rejectWithValue("No downloads available");
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchNewFilesData = createAsyncThunk(
  "newFilesData/fetchNewFilesData",
  async(_,{rejectWithValue})=>{
    try {
      let client_id = window.localStorage.getItem("clientID");
      const result = await getDownloadsClientFiles(client_id);

      if (!result || result.length === 0) {
        return rejectWithValue("No downloads available");
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
});


export const fetchChatHistoryData = createAsyncThunk(
  "newChatData/fetchChatHistoryData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/chatbot/all/`);
      
      if (!response.data || response.data.length === 0) {
        return rejectWithValue("No chat history available");
      }

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message || "Server error occurred");
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        // Something happened in setting up the request
        return rejectWithValue(error.message);
      }
    }
  }
);
export const fetchClientsIdsData = createAsyncThunk(
  "newClientData/fetchNewClientData",
  async(_,{rejectWithValue})=>{
    try {
      const result = await getClientIds();

      if (!result || result.length === 0) {
        return rejectWithValue("No downloads available");
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
});

export const newexcelSlice = createSlice({
  name: "newexcelData",
  initialState: {
    excels: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewExcelsData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchNewExcelsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.excels = action.payload;
      })
      .addCase(fetchNewExcelsData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.excels = [];
      });
  },
});

export const chatDataSlice = createSlice({
  name: "chatData",
  initialState: {
    chatData: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatHistoryData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchChatHistoryData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chatData = action.payload;
      })
      .addCase(fetchChatHistoryData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.excels = [];
      });
  },
});

export const newFilesSlice = createSlice({
  name: "newfilesData",
  initialState: {
    files: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewFilesData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchNewFilesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.files = action.payload;
      })
      .addCase(fetchNewFilesData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.files = [];
      });
  },
});

export const newClientIdsSlice = createSlice({
  name: "newClientIdData",
  initialState: {
    clientIds: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientsIdsData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchClientsIdsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clientIds = action.payload;
      })
      .addCase(fetchClientsIdsData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.clientIds = [];
      });
  },
});

export const fetchData = createAsyncThunk('feature/fetchData', async () => {
  // Fetch the data (mock example here)
  const response = await fetch('https://api.example.com/data');
  const data = await response.json();
  return data;
});

export const fetchapiData = createAsyncThunk(
  'feature/fetchData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('https://api.example.com/data');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      // Save to IndexedDB upon successful fetch
      await saveToDb('feature', data);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const featureSlice = createSlice({
  name: 'feature',
  initialState: { data: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { increment, decrement } = counterSlice.actions;
export const { setUser, logoutUser } = userSlice.actions;

export const {setNewReportAccessData} = newreportaccessSlice.actions;
export const {setNewExcelsData} = newexcelSlice.actions;
export const selectnewreportaccessData = (state) =>state.newreportaccessdata.value
export const selectnewexcelData = (state) =>state?.newexcelData?.excels
export const selectnewFileData = (state)=>state?.newfilesData?.files;
export const selectnewClientIdsData = (state)=>state?.newClientIdData?.clientIds;
export const newClientIdsAccessReducer = newClientIdsSlice.reducer
export const newFileAccessReducer = newFilesSlice.reducer;
export const newReportAccessReducer = newreportaccessSlice.reducer;
export const newexcelReducer = newexcelSlice.reducer;
export const chatHisrtoryData = (state)=>state?.chatData?.chatData;
export const chatHistoryReducer = chatDataSlice.reducer
export const counterReducer = counterSlice.reducer;
export const userReducer = userSlice.reducer;
export const featureReducer = featureSlice.reducer;

