import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import { HOME_URL } from "../../../constants/constants";
import SearchIcon from "../../../components/svg/SearchIcon";
import MultiSelect from "../../../components/MultiSelect";
import StyledTable from "../../../components/StyledTable";
import Pagination from "../../../components/Pagination";
import axios from "axios";
import MasterTable from "../../../components/MasterTable";
import { media } from "../../../utils/media";

const AdminTable = ({
  compSearchString = "",
  setCompSearchString = () => null,
  tags = [],
  onTagClick = () => null,

  allParameters = [],
  rowsData = [],
  dataMonth,

  industryList = [],
  setSelectedIndustry = () => null,

  valuationList = [],
  setSelectedValuation = () => null,

  sortedParameter,
  setSortedParameter = () => null,
  sortedDirection,
  setSortedDirection = () => null,

  onRangeFilterApply = () => null,
  filteredRange = {},

  valueFilterData = {},
  onValueFilterApply = () => null,
}) => {
  // API whole data
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const dataPerPage = 10;

  // useEffect(() => {
  //   setFilteredCompDataList(
  //     rowsData.filter((comp) => {
  //       let allParameterInRange = true;
  //       for (const key in parameterRange) {
  //         if (parameterRange[key] && parameterRange[key].length) {
  //           if (!comp.paramData) {
  //             /* have filter but no data */
  //             return false;
  //           } else if (!comp.paramData[key]) {
  //             /* have filter but no data */
  //             return false;
  //           } else {
  //             /* check if this parameter in range */
  //             if (
  //               !parameterRange[key].some(
  //                 (range) =>
  //                   comp.paramData[key].current_value >= range.low &&
  //                   comp.paramData[key].current_value <= range.high
  //               )
  //             ) {
  //               /* not found in any range */
  //               allParameterInRange = false;
  //             }
  //           }
  //         }
  //       }
  //       return allParameterInRange;
  //     })
  //   );
  // }, [visibaleCompDataList, parameterRange]);

  useEffect(() => {
    setCurrentPage(1);
    setTotalPage(Math.ceil(rowsData.length / dataPerPage));
  }, [rowsData]);

  const startIndex = (currentPage - 1) * dataPerPage;
  const currentRowsData = rowsData.slice(startIndex, startIndex + dataPerPage);

  return (
    <Wrapper>
      <ShadowWrap>
        <ShadowBox>
          {/* <CompanySearch>
            <CompanyInputWrapper>
              <CompanyInput
                placeholder="Search Company Name"
                type="search"
                value={compSearchString}
                onChange={(e) => {
                  setCompSearchString(e.target.value);
                }}
              ></CompanyInput>
              <SearchWrapper>
                <SearchIcon />
              </SearchWrapper>
            </CompanyInputWrapper>
          </CompanySearch> */}
          <TagsWrapper>
            {tags.map((tag) => (
              <TagDiv
                id={tag.id}
                key={tag.id}
                selected={tag.selected}
                onClick={() => {
                  onTagClick(tag.id);
                }}
              >
                {tag.name}
              </TagDiv>
            ))}
          </TagsWrapper>

          <TableMenu>
            <TableMenuLeft>For the last 12 months</TableMenuLeft>
            <TableMenuRight>
              <Label>Filter by Sectors:</Label>
              <MultiSelect
                options={industryList}
                onSelectedChange={setSelectedIndustry}
                keyFieldName="industry_id"
                valueFieldName="industry_name"
                selectedOptions={industryList.filter((obj) => obj.selected)}
                mutileSelect={true}
                sortOptions={true}
                placeholder="Filter by Sectors..."
              />
              {/* <Label>Filter by Valuation:</Label>
              <MultiSelect
                options={valuationList}
                onSelectedChange={setSelectedValuation}
                keyFieldName="id"
                valueFieldName="name"
                selectedOptions={valuationList.filter((obj) => obj.selected)}
                mutileSelect={true}
                placeholder="Filter by Valuations..."
              /> */}
            </TableMenuRight>
          </TableMenu>
          <MasterTable
            columnsData={allParameters}
            rowsData={rowsData}
            sortedColKey={sortedParameter}
            setSortedColKey={setSortedParameter}
            sortedDirection={sortedDirection}
            setSortedDirection={setSortedDirection}
            headerBackground={"#D4EEFF"}
            onRangeFilterApply={onRangeFilterApply}
            filteredRange={filteredRange}

            valueFilterData = {valueFilterData}
            onValueFilterApply = {onValueFilterApply}
          />
        </ShadowBox>
      </ShadowWrap>
      {/* <PaginationWrapper>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
        />
      </PaginationWrapper> */}
    </Wrapper>
  );
};

export default AdminTable;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ShadowWrap = styled.div`
  min-height: 400px;
  max-width: 100%;
  margin-bottom:10px;
`;

const ShadowBox = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px 0px #00000014;
`;

const CompanySearch = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 10px;
  align-items: center;
  background-color: #ffffff;
`;

const CompanyInputWrapper = styled.div`
  display: flex;
  width: 60%;
  height: 60px;
  position: relative;
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 22px;
  top: 18px;
`;

const TagsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 9px 9px 10px;
  flex-wrap: wrap;
  background-color: #ffffff;
  justify-content: center;
  ${media.small`
      padding:0px;

    `}
`;

const TagDiv = styled.div`
  height: 28px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px 10px 6px 10px;
  background-color: ${(props) => (props.selected ? "#0099FF" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#FFFFFF" : "#262e40")};
  margin: 6px 6px;
  border-radius: 24px;
  cursor: pointer;
  ${media.small`
      height: 15px;
  font-size: 9px;
  line-height: 10px;
  padding: 3px 5px 3px 5px;
      margin: 3px 3px;
  border-radius: 12px;
    `}
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 60px 50px;
`;

const Header1 = styled.div`
  background-color: white;
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const BreadCrumbSpan = styled.span`
  &:hover {
    color: #20a6ff;
    cursor: pointer;
  }
`;

const LHSTitlediv = styled.div`
  font-weight: 500;
  font-size: 30px;
`;

const RHSTitlediv = styled.div`
  display: flex;
  gap: 10px;
`;

const TableMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 56px;
  padding: 15px 0px 20px;
  background-color: #ffffff;
  align-items: center;
`;

const TableMenuLeft = styled.div`
  margin-left: 15px;
  ${media.small`
    font-size:12px;
    
    `}
`;

const TableMenuRight = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const Label = styled.div`
  margin: 0 5px 0px 10px;
`;

const SelectWraper = styled.div`
  margin-left: 10px;
`;

const PaginationWrapper = styled.div`
  padding: 20px 0 10px;
`;
