import React from "react";
import styled from "styled-components";
import SortFilterIcon from "../SortFilterIcon";
import ToolTip from "./ToolTip";
import { media } from "../../utils/media";
import Loader from "../Loader";

const MasterTable = ({
  columnsData = [
    {
      key: "col1",
      value: "Player",
      sorting: false,
      range_filter: false,
      value_filter: false,
    },
    {
      key: "col2",
      value: "Sector",
      sorting: false,
      range_filter: true,
      range_filter_data: { low: 10, high: 90 },
    },
    {
      key: "col3",
      value: <div style={{ color: "blue", fontWeight: 600 }}>ABC value</div>,
      sorting: true,
      range_filter: false,
      value_filter: false,
    },
    {
      key: "col4",
      value: (
        <div
          style={{
            color: "red",
            backgroundColor: "lightblue",
            fontWeight: 600,
          }}
        >
          CBA value
        </div>
      ),
      sorting: true,
      range_filter: true,
      range_filter_data: { low: 100, high: 900 },
      value_filter: false,
    },
  ],
  rowsData = [
    {
      col1: {
        value: "Amazon",
        toolTip: (
          <div style={{ width: "400px" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </div>
        ),
      },
      col2: {
        value: "Fashion",
        toolTip: null,
      },
      col3: {
        value: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>10%</p>
            <b>99</b>
          </div>
        ),
        toolTip1: <div>This is sample tooltip</div>,
      },
      col4: {
        value: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p style={{ color: "red" }}>20%</p>
            <b>88</b>
          </div>
        ),
        toolTip: (
          <div style={{ width: "400px" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </div>
        ),
      },
    },
    {
      col1: {
        value: "Flipkart",
        toolTip: null,
      },
      col2: {
        value: "Fashion",
        toolTip: null,
      },
      col3: {
        value: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>3%</p>
            <b>67</b>
          </div>
        ),
        toolTip1: (
          <div style={{ backgroundColor: "yellow" }}>
            This is sample tooltip yello
          </div>
        ),
      },
      col4: {
        value: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p style={{ color: "red" }}>0.8%</p>
            <b>91</b>
          </div>
        ),
        toolTip1: (
          <div>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.{" "}
          </div>
        ),
      },
    },
  ],
  sortedColKey = "",
  setSortedColKey = () => null,
  sortedDirection = "",
  setSortedDirection = () => null,
  emptyDataPlaceholder = "-",
  onRangeFilterApply = (columnKey, selectedRanges) => null,
  filteredRange = {
    col4: [
      {
        high: 200,
        id: 2,
        low: 300,
        name: "200 - 300",
      },
      {
        high: 300,
        id: 3,
        low: 400,
        name: "300 - 400",
      },
    ],
  },

  valueFilterData = {},
  onValueFilterApply = (columnKey, valueFilterOptions) => null,

  headerBackground = "#FFFFFF", //"#d4eeff"
  headerTextColor = "#444444", //"#d4eeff"
  toolTipDirection = "top",
  toolGap = -10,
  scroll = false,
  maxHeight = null, //400px, 80vh, null
  removesortText = false,
  autoCellHeight = false,
  loading,
}) => {
  const onColumnSortClickFun = (col, direction) => {
    if (!sortedColKey || sortedColKey !== col.key) {
      setSortedColKey(col.key);
    }
    if (sortedDirection !== direction) {
      setSortedDirection(direction);
    }
  };
  return (
    <TableWrapper>
      <TableScrollBody scroll={scroll} maxHeight={maxHeight}>
        <Table
          headerBackground={headerBackground}
          headerTextColor={headerTextColor}
          autoCellHeight={autoCellHeight}
        >
          <thead>
            <tr>
              {columnsData.map((col, idx) => {
                return (
                  <th key={idx}>
                    <HeightContainer>
                      {typeof col.value === "string" ||
                        typeof col.value === "number" ? (
                        <TextContainer
                          dangerouslySetInnerHTML={{
                            __html: col.value,
                          }}
                        />
                      ) : (
                        col.value
                      )}
                      <Sortbutton
                        width={col.range_filter && col.sorting ? null : null}
                      >
                        {/* {col.range_filter && (
                        <FilterIcon
                          low={col.range_filter_data?.low || 0}
                          high={col.range_filter_data?.high || 0}
                          filteredRange={filteredRange[col.key] || []}
                          onApplySelected={(selectedRanges) =>
                            onRangeFilterApply(col.key, selectedRanges)
                          }
                        />
                      )} */}
                        {(col.sorting ||
                          col.range_filter ||
                          col.value_filter) && (
                            <SortFilterIcon
                              apply_sorting={col.sorting}
                              sorting_direction={
                                sortedColKey === col.key ? sortedDirection : ""
                              }
                              onSortingClick={(direction) =>
                                onColumnSortClickFun(col, direction)
                              }
                              apply_range_filter={col.range_filter}
                              low={col.range_filter_data?.low || 0}
                              high={col.range_filter_data?.high || 0}
                              filteredRange={filteredRange[col.key] || []}
                              onApplySelected={(selectedRanges) =>
                                onRangeFilterApply(col.key, selectedRanges)
                              }
                              apply_value_filter={col.value_filter}
                              value_filter_data={valueFilterData[col.key] || []}
                              onApplyValueFilter={(valueFilterOptions) =>
                                onValueFilterApply(col.key, valueFilterOptions)
                              }
                              removetext={removesortText}
                            />
                          )}
                      </Sortbutton>
                    </HeightContainer>
                  </th>
                );
              })}
            </tr>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td colSpan={columnsData.length} style={{ position: 'relative', height: '75vh' }}>
                  <LoaderOverlay>
                    <Loader />
                  </LoaderOverlay>
                </td>
              </tr>
            </tbody>
          ) : rowsData.length > 0 && (
            <>
              {Boolean(rowsData.length) && (
                <tbody>
                  {rowsData.map((row, idx) => (
                    <tr key={idx}>
                      {columnsData.map((col, idx) => {
                        const alignMent = col.align || "center";
                        let value =
                          row[col.key]?.value != null
                            ? row[col.key]?.value
                            : emptyDataPlaceholder;
                        value =
                          typeof value === "string" || typeof value === "number" ? (
                            <TextContainer
                              align={alignMent}
                              minWidth={col.min_width}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: value,
                                }}
                              />
                            </TextContainer>
                          ) : (
                            value
                          );
                        const toolTip = row[col.key]?.toolTip || null;
                        return (
                          <td key={idx}>
                            <HeightContainer>
                              <ToolTip
                                value={value}
                                toolTip={toolTip}
                                toolTipDirection={toolTipDirection}
                                gap={toolGap}
                              />
                            </HeightContainer>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              )}
            </>
          )}
        </Table>
        {!loading && !rowsData.length && <NoDataMessage>No Data Available</NoDataMessage>}
      </TableScrollBody>
    </TableWrapper>
  );
};

export default MasterTable;

const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  // max-height: 100vh;
  ${media.small`
  // max-height: 50vh;
`}
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TableScrollBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: ${(props) =>
    props.maxHeight ? "auto" : props.scroll ? "scroll" : "visible"};
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight}` : null)};
  // min-height: 200px;
  ::-webkit-scrollbar {
    display: none;
  }
  background-color: #ffffff;
  ${media.small`
    min-height: 100px;
  `}
`;

const Table = styled.table`
  border-collapse: collapse;
  width: auto;
  background-color: #ffffff;
  cursor: default;
  position: relative;

  color: #444444;
  thead {
    box-shadow: 0px 1px 2px 0px #00000040;
    box-shadow: 0px 4px 2px -2px #00000040;
    background-color: ${(props) => `${props.headerBackground}`};
    color: ${(props) => `${props.headerTextColor}`};
    position: sticky;
    top: 0;
    z-index: 5;
  }
  tr {
    min-height: ${(props)=> props.autoCellHeight? "auto": "55px"};
    max-height: ${(props)=> props.autoCellHeight? "auto": "70px"};
    ${media.small`
      min-height: ${(props)=> props.autoCellHeight? "auto": "30px"};
      max-height: ${(props)=> props.autoCellHeight? "auto": "35px"};
    `}
    &:nth-child(even) {
      background: #f2f2f280;
    }

    &:nth-child(odd) {
      background: #ffffff;
    }
  }
  th {
    background-color: ${(props) => `${props.headerBackground}`};
    color: ${(props) => `${props.headerTextColor}`};
  }
  td,
  th {
    text-align: left;
    border: 1px solid #d1d1d1;
    width: 500px;
    min-height: ${(props)=> props.autoCellHeight? "auto": "55px"};
    max-height: ${(props)=> props.autoCellHeight? "auto": "65px"};
    height: ${(props)=> props.autoCellHeight? "auto": "60px"};
    ${media.small`
      min-height: ${(props)=> props.autoCellHeight? "auto": "30px"};
      max-height: ${(props)=> props.autoCellHeight? "auto": "35px"};
      height: ${(props)=> props.autoCellHeight? "auto": "30px"};
    `}
  }
  th {
    white-space: nowrap;
  }

  tr:first-child th {
    border-top: none;
  }

  tr:last-child th,
  tr:last-child td {
    border-bottom: none;
  }

  td:first-child {
    border-left: none;
  }

  th {
    border-left: none;
    border-right: none;
  }

  td:last-child {
    border-right: none;
  }

  tr {
    th:nth-child(1) {
      position: sticky;
      border-right: 1px solid #d1d1d1;
      left: 0;
      background-color: ${(props) => `${props.headerBackground}`};
      color: ${(props) => `${props.headerTextColor}`};
      z-index: 4;
    }
    &:nth-child(even) {
      td:nth-child(1) {
        position: sticky;
        border-right: 1px solid #d1d1d1;
        left: 0;
        background-color: #f8f8f8;
        z-index: 4;
      }
    }
    &:nth-child(odd) {
      td:nth-child(1) {
        position: sticky;
        border-right: 1px solid #d1d1d1;
        left: 0;
        background-color: #ffffff;
        z-index: 4;
      }
    }
  }

  td:nth-child(1)::after {
    content: "";
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    width: 1px;
    background-color: #d1d1d1;
  }

  th:nth-child(1)::after {
    content: "";
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    width: 1px;
    background-color: #ffffff;
  }
`;

const HeightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  ${media.small`
    padding: 7px;
  `}
`;

const TextContainer = styled.div`
  text-align: left;
  text-align: ${(props) => `${props.align}`};
  ${(props) => (props.minWidth ? `min-width: ${props.minWidth}px;` : null)};
`;

const Sortbutton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  position: relative;
  margin-left: 15px;
  ${media.small`
  margin-left: 7px;
`}
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 60px 10px;
  ${media.small`
  padding: 30px 5px;
`}
`;
const LoaderOverlay = styled.div`
  position: absolute;
  top: 30%;
  left: 30%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
`;
